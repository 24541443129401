import React, { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import residential from '../../images/residential.svg';
import commercial from '../../images/commercial.svg';
import rent from '../../images/rent.svg';
import sale from '../../images/sale.svg';
import delete_icon from '../../images/delete_icon.svg';
import closeModal from '../../images/close.svg';
import folder from '../../images/folder.svg';
import deleteIcon from '../../images/delete.svg';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Modal, TextField, Button, RadioGroup, FormControlLabel, Radio, Checkbox, } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import { useTranslation } from "react-i18next";
import Pagination from '@mui/material/Pagination';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import Switch from '@mui/material/Switch';





// <!-- Google Fonts -->
import '../../css/material-icon.css';

// <!-- Bootstrap Core Css -->
import '../../plugins/bootstrap/css/bootstrap.css';

// <!-- Waves Effect Css -->
import '../../plugins/node-waves/waves.css';

// <!-- Animation Css -->
import '../../plugins/animate-css/animate.css';

// <!-- Bootstrap Select Css -->
import '../../plugins/bootstrap-select/css/bootstrap-select.css';


// <!-- JQuery DataTable Css -->
import '../../plugins/jquery-datatable/skin/bootstrap/css/dataTables.bootstrap.css';
// <!-- Bootstrap Material Datetime Picker Css -->
import '../../plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css';

// <!-- Bootstrap DatePicker Css -->
import '../../plugins/bootstrap-datepicker/css/bootstrap-datepicker.css';

// <!-- Wait Me Css -->
import '../../plugins/waitme/waitMe.css';

// <!-- Morris Chart Css-->
import '../../plugins/morrisjs/morris.css';

// <!-- Custom Css -->
import '../../css/style.css';
import '../../css/custom.css';
import '../../css/materialize.css';

import '../../css/uicons-bold-rounded.css';




// <!-- Style -->
// <!--<link rel="stylesheet" href="css/style.css">-->

// <!-- AdminBSB Themes. You can choose a theme from css/themes instead of get all themes -->
import '../../css/themes/all-themes.css';
import { useNavigate } from 'react-router-dom';
import Topbar from '../../component/Topbar';
import Sidebar from '../../component/Sidebar';
import { scroller } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { addNotesApi, addPropertyApi, checkPublishCreditsApi, countryApi, deleteMediaImageApi, deletePropertyDocumentsListApi, floorPlanDocListApi, getPropertyDetailApi, logoutApi, mediaImageList, notesListPropertyApi, occupancyTypeApi, propertyCategoryApi, propertyDocumentsListApi, propertyModeApi, renovationTypeApi, tenureApi, updatePropertyApi, uploadVideoUrlApi } from '../../redux/ApiActionCreator';
import axios from 'axios';
import config from '../../redux/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomBadge from '../../component/CustomBadge';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;






const numbers = [
    {
        Id: 1,
        Name: "1"
    },
    {
        Id: 2,
        Name: "2"
    },
    {
        Id: 3,
        Name: "3"
    },
    {
        Id: 4,
        Name: "4"
    },
    {
        Id: 5,
        Name: "5"
    },
    {
        Id: 6,
        Name: "6"
    },
    {
        Id: 7,
        Name: "7"
    },
    {
        Id: 8,
        Name: "8"
    },
    {
        Id: 9,
        Name: "9"
    },
    {
        Id: 10,
        Name: "10"
    },
    {
        Id: 11,
        Name: "11"
    },
    {
        Id: 12,
        Name: "12"
    },
    {
        Id: 13,
        Name: "13"
    },
    {
        Id: 14,
        Name: "14"
    },
    {
        Id: 15,
        Name: "15"
    },
    {
        Id: 16,
        Name: "16"
    },
    {
        Id: 17,
        Name: "17"
    },
    {
        Id: 18,
        Name: "18"
    },
    {
        Id: 19,
        Name: "19"
    },
    {
        Id: 20,
        Name: "20"
    },
];







function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function PropertyDetail() {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const numbersBedroom = [
        {
            Id: -1,
            Name: "Studio"
        },
        {
            Id: 1,
            Name: "1"
        },
        {
            Id: 2,
            Name: "2"
        },
        {
            Id: 3,
            Name: "3"
        },
        {
            Id: 4,
            Name: "4"
        },
        {
            Id: 5,
            Name: "5"
        },
        {
            Id: 6,
            Name: "6"
        },
        {
            Id: 7,
            Name: "7"
        },
        {
            Id: 8,
            Name: "8"
        },
        {
            Id: 9,
            Name: "9"
        },
        {
            Id: 10,
            Name: "10"
        },
        {
            Id: 11,
            Name: "11"
        },
        {
            Id: 12,
            Name: "12"
        },
        {
            Id: 13,
            Name: "13"
        },
        {
            Id: 14,
            Name: "14"
        },
        {
            Id: 15,
            Name: "15"
        },
        {
            Id: 16,
            Name: "16"
        },
        {
            Id: 17,
            Name: "17"
        },
        {
            Id: 18,
            Name: "18"
        },
        {
            Id: 19,
            Name: "19"
        },
        {
            Id: 20,
            Name: "20"
        },
    ];
    const field1Ref = useRef();
    const field2Ref = useRef();
    const field3Ref = useRef();
    const field4Ref = useRef();
    const field5Ref = useRef();
    const field6Ref = useRef();
    const field7Ref = useRef();
    const field8Ref = useRef();



    const theme = useTheme();
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const fieldDefaultRef = useRef();
    const fieldDefaultRefTop = useRef();
    const [personName, setPersonName] = useState([]);
    const [page, setPage] = React.useState(1);
    const [pageDocumentList, setPageDocumentList] = React.useState(1);

    const [residentCategory, setResidentCategory] = useState(2)
    const [rentalCategory, setRentalCategory] = useState(1)
    const [open, setOpen] = useState(false);
    const [propertyPopupOpen, setpropertyPopupOpen] = useState(false)
    const [accordingList, setAccordingList] = useState(true)
    const [specificationAccordingList, setSpecificationAccordingList] = useState(true)
    const [pricingAccordingList, setPricingAccordingList] = useState(true)
    const [descriptionAccordingList, setDescriptionAccordingList] = useState(true)
    const [amenitiesAccordingList, setAmenitiesAccordingList] = useState(true)
    const [propertyAccordingList, setPropertyAccordingList] = useState(true)
    const [floorAccordingList, setFloorAccordingList] = useState(true)

    const [locationCoordinates, setLocationCoordinates] = useState('')
    const [floorPlanPopup, setfloorPlanPopup] = useState(false)
    const [floorPlanSelectedId, setfloorPlanSelectedId] = useState('')
    const [selectedChips, setSelectedChips] = useState([]);
    const [selectAminitiesSelected, setselectAminitiesSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('one');
    const [cityDataSeachOption, setcityDataSeachOption] = useState([])
    const [prAddressSelected, setPrAddressSelected] = useState('')
    const [cityAddressError, setcityAddressError] = useState('')
    const [urlLink_360, setUrlLink_360] = useState("")
    const [videoTourUrl, setVideoTourUrl] = useState("")
    const today = new Date().toISOString().split('T')[0];
    const [propertyType, setPropertyType] = useState("")
    const [propertyTypeError, setPropertyTypeError] = useState("")
    const [size, setSize] = useState("")
    const [sizeError, setSizeError] = useState("")
    const [noOfBedrooms, setNoOfBedrooms] = useState("")
    const [noOfBathrooms, setNoOfBathrooms] = useState("")
    const [waterAndElectricity, setWaterAndElectricity] = useState("")
    const [noOfParkingSpace, setNoOfParkingSpace] = useState("")
    const [furnishingType, setFurnishingType] = useState("")
    const [propertyDeveloper, setPropertyDeveloper] = useState("")
    const [tenureOfTheProperty, setTenureOfTheProperty] = useState("")
    const [buildYear, setBuildYear] = useState("")
    const [totalPlotSize, setTotalPlotSize] = useState("")
    const [plotNumber, setPlotNumber] = useState("")
    const [occupancy, setOccupancy] = useState("")
    const [renovationType, setRenovationType] = useState("")
    const [views, setViews] = useState("")
    const [monthlyPrice, setMonthlyPrice] = useState("")
    const [monthlyPriceError, setMonthlyPriceError] = useState("")
    const [title, setTitle] = useState("")
    const [titleError, setTitleError] = useState("")
    const [language, setLanguage] = useState(1)
    const [languageError, setLanguageError] = useState("")
    const [description, setDescription] = useState("")
    const [descriptionError, setDescriptionError] = useState("")
    const [references, setReferences] = useState("")
    const [referencesError, setReferencesError] = useState("")
    const [assignedUser, setAssignedUser] = useState("")
    const [assignedUserError, setAssignedUserError] = useState("")
    const [owner, setOwner] = useState("")
    const [availableFrom, setAvailableFrom] = useState("")
    const [startDate, setStartDate] = useState("")
    const [expiryDate, setExpiryDate] = useState("")
    const [startDateError, setStartDateError] = useState("")
    const [expiryDateError, setExpiryDateError] = useState("")
    const [propertySelectedDocument, setpropertySelectedDocument] = useState('')
    const [countryId, setcountryId] = useState('')
    const [stateId, setstateId] = useState('')
    const [cityId, setcityId] = useState('')
    const [upgradePlanOpen, setUpgradePlanOpen] = React.useState(false);
    const [publishOpen, setPublishOpen] = React.useState(false);
    const [floorPlanPage, setfloorPlanPage] = useState(1)
    const [mainSubscriptionTypeSelected, setMainSubscriptionTypeSelected] = useState("")
    const [subscriptionIdSelectedFeatured, setSubscriptionIdSelectedFeatured] = useState(2)
    const [subscriptionIdSelectedPremium, setSubscriptionIdSelectedPremium] = useState(4)
    const [propertyCurrentSubscriptionPlanId, setPropertyCurrentSubscriptionPlanId] = useState("")
    const [notes, setNotes] = useState("")
    const [notesError, setNotesError] = useState("")
    const [createdDateStr, setcreatedDateStr] = useState('')
    const [modifiedDateStr, setmodifiedDateStr] = useState('')
    const [createdByName, setcreatedByName] = useState('')
    const [qualityScore, setQualityScore] = useState("")
    const [isExclusive, setIsExclusive] = useState(false)
    const [inputTypeDate, setInputTypeDate] = useState('text');
    const [selectedAvailabilityId, setSelectedAvailabilityId] = useState(1);
    const [inputTypeStartDate, setInputTypeDateStartDate] = useState('text');
    const [inputTypeExpiryDate, setInputTypeDateExpiryDate] = useState('text');
    const [autoRenew, setAutoRenew] = useState(false)
    const [featuredAutoRenewCheckBox, setFeaturedAutoRenewCheckBox] = useState(true)
    const [premiumAutoRenewCheckBox, setPremiumAutoRenewCheckBox] = useState(true)

    const propertyMode = useSelector((state) => state?.apiReducer?.propertyMode)
    const token = useSelector((state) => state?.apiReducer?.loginData?.Data?.accessToken)
    const userId = useSelector((state) => state?.apiReducer?.loginData?.Data?.userId)
    const addPropertyData = useSelector((state) => state?.apiReducer?.addPropertyData)
    const userRole = useSelector((state) => state?.apiReducer?.loginData?.Data?.userRole[0])
    const imageMediaListData = useSelector((state) => state?.apiReducer?.imageMediaListData)
    const amenitiesMediaData = useSelector((state) => state?.apiReducer?.amenitiesMediaData)
    const propertyDocumentsListData = useSelector((state) => state?.apiReducer?.propertyDocumentsListData)
    const checkPublishCreditsData = useSelector((state) => state?.apiReducer?.checkPublishCreditsData)
    const propertyOfflineData = useSelector((state) => state?.apiReducer?.propertyOfflineData)
    const propertyMasterData = useSelector((state) => state?.apiReducer?.propertyMasterData)
    const getPropertyData = useSelector((state) => state?.apiReducer?.getPropertyData)
    const notesPropertyData = useSelector((state) => state?.apiReducer?.propertyNotesListData)
    const floorPlanDocListData = useSelector((state) => state?.apiReducer?.floorPlanDocListData)
    const ownerPropertyEdit = useSelector((state) => state?.apiReducer?.ownerPropertyEdit)


    const selectedPropertyTypeDropdown = residentCategory == 2 && rentalCategory == 1 ? propertyMasterData?.ResidentialRent : residentCategory == 2 && rentalCategory == 2 ? propertyMasterData?.ResidentialSale : residentCategory == 1 && rentalCategory == 1 ? propertyMasterData?.CommertialRent : propertyMasterData?.CommertialSale
    const propertyTypesForBedroomsDisplay = [1, 3, 9, 10, 7, 8, 6, 2]


    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow digits, decimal point, and backspace
        if (!/^\d$/.test(keyValue) && keyCode !== 8) {
            event.preventDefault();
        }

        // Prevent typing a minus sign
        if (keyCode === 45) {
            event.preventDefault();
        }
    };


    const handleFeaturedAutoRenewCheckBox = (event) => {

        setFeaturedAutoRenewCheckBox(event.target.checked); // Update the state when checkbox is changed
    };
    const handlePremiumAutoRenewCheckBox = (event) => {


        setPremiumAutoRenewCheckBox(event.target.checked); // Update the state when checkbox is changed
    };
    const handleAvailabilityChange = (availabilityId) => {
        setSelectedAvailabilityId(availabilityId);
    };


    const handleFocusDate = (e) => {
        setInputTypeDate('date');
        e.target.blur()
    };




    const handleFocusStartDate = (e) => {
        setInputTypeDateStartDate('date');
        e.target.blur()
    };



    const handleFocusExpiryDate = (e) => {
        setInputTypeDateExpiryDate('date');
        e.target.blur()
    };


    useEffect(() => {
        scroller.scrollTo(fieldDefaultRefTop.current.id, {
            smooth: false,
            offset: -100, // Adjust as needed
        });
    }, [])

    // GET QUALITY SCORE API CALL
    useEffect(() => {

        if (!propertyMode?.mode == 0) {
            getQualityScore()
        }
    }, [value])


    const getQualityScore = async () => {

        setLoading(true)

        try {
            const response = await axios.get(`${config.baseURL}/QualityScore/GetQualityScoreByPropertyId?PropertyId=${propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    setQualityScore(response?.data?.Data)

                    setLoading(false)

                } else if (response.data.Status == 'ERROR') {
                    setLoading(false)
                }

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)


        }
    };
    useEffect(() => {
        dispatch(countryApi(token))
            .then((response) => {

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {

                    dispatch(logoutApi())
                    navigate('/')

                }


            });

    }, [])

    // GET PROPERTY DETAIL API CALL
    useEffect(() => {
        if (propertyOfflineData.propertyType != 'create' || !propertyMode?.mode == 0) {
            dispatch(getPropertyDetailApi(token, getPropertyData?.Id))
        }

    }, [])


    // NOTES API CALL
    useEffect(() => {
        dispatch(notesListPropertyApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
    }, [value])

    function convertDateFormat(inputDate) {
        // Split the date string into parts
        const parts = inputDate.split('-');

        // Rearrange the parts to dd-mm-yyyy format
        const outputDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

        return outputDate;
    }

    useEffect(() => {
        if (propertyOfflineData.propertyType != 'create' || !propertyMode?.mode == 0) {
            setResidentCategory(getPropertyData?.CategoryId)
            setRentalCategory(getPropertyData?.OfferingTypeId)
            setPropertyType(getPropertyData?.PropertytypeId == null ? '' : getPropertyData?.PropertytypeId)
            setSize(getPropertyData?.Size == null || getPropertyData?.Size == 0 ? '' : getPropertyData?.Size)
            setNoOfBedrooms(getPropertyData?.Noofbedrooms == null || getPropertyData?.Noofbedrooms == 0 ? '' : getPropertyData?.Noofbedrooms)
            setNoOfBathrooms(getPropertyData?.Noofbathrooms == null || getPropertyData?.Noofbathrooms == 0 ? '' : getPropertyData?.Noofbathrooms)
            setWaterAndElectricity(getPropertyData?.Waternelectricity == null ? '' : getPropertyData?.Waternelectricity)
            setNoOfParkingSpace(getPropertyData?.Noofparking == null ? '' : getPropertyData?.Noofparking)
            setFurnishingType(getPropertyData?.FurnishingtypeId == null ? '' : getPropertyData?.FurnishingtypeId)
            setTenureOfTheProperty(getPropertyData?.PropertytenureId == null ? '' : getPropertyData?.PropertytenureId)
            setTotalPlotSize(getPropertyData?.Totalplotsize)
            setOccupancy(getPropertyData?.OccupancytypeId == null ? '' : getPropertyData?.OccupancytypeId)
            setRenovationType(getPropertyData?.RenovationtypeId == null ? '' : getPropertyData?.RenovationtypeId)
            setViews(getPropertyData?.Views)
            setMonthlyPrice(getPropertyData?.MonthlyPrice == null || getPropertyData?.MonthlyPrice == 0 ? '' : getPropertyData?.MonthlyPrice)
            setTitle(getPropertyData?.Title)
            setDescription(getPropertyData?.Description.replace(/^"|"$/g, '').replace(/\\n/g, '\n'))
            setLanguage(getPropertyData?.LanguageId == null ? '' : getPropertyData?.LanguageId)
            setSelectedChips(getPropertyData?.Amenities)
            const filteredArray = filterArrayById(propertyMasterData?.Amenities, getPropertyData?.Amenities);
            setselectAminitiesSelected(filteredArray)
            setUrlLink_360(getPropertyData?.ThreeSixtyUrlLink)
            setVideoTourUrl(getPropertyData?.VideoTourUrl)
            setcreatedDateStr(getPropertyData?.CreatedDateStr)
            setmodifiedDateStr(getPropertyData?.ModifiedDateStr)
            setcreatedByName(getPropertyData?.CreatedByName)
            setPrAddressSelected(getPropertyData?.Address2)
            setcountryId(getPropertyData?.CountryId)
            setstateId(getPropertyData?.StateId)
            setcityId(getPropertyData?.CityId)
            setReferences(getPropertyData?.Reference)
            setAssignedUser(getPropertyData?.AssignedtouserId == null ? '' : getPropertyData?.AssignedtouserId)
            setOwner(getPropertyData?.OwnerId == null ? '' : getPropertyData?.OwnerId)
            setIsExclusive(getPropertyData?.Isexclusive)
            setAutoRenew(getPropertyData?.SubscriptionPlanId == null ? false : getPropertyData?.SubscriptionPlanId == 1 ? getPropertyData?.IsAutoRenew : getPropertyData?.IsBoosterAutoRenew)
            setAvailableFrom(getPropertyData?.AvailableFromStr == null ? null : convertDateFormat(getPropertyData?.AvailableFromStr))
            setStartDate(getPropertyData?.ExclusiveStartDateStr == null ? null : convertDateFormat(getPropertyData?.ExclusiveStartDateStr))
            setExpiryDate(getPropertyData?.ExclusiveEndDateStr == null ? null : convertDateFormat(getPropertyData?.ExclusiveEndDateStr))
            setSelectedAvailabilityId(getPropertyData?.AvailabilitytypeId)
            const data = {
                lat: getPropertyData?.Lat,
                lng: getPropertyData?.Lng
            }

            setLocationCoordinates(data)
        }

        if (propertyOfflineData.propertyType == 'create' || propertyMode?.mode == 0) {
            setAssignedUser(userId)
        }

    }, [])


    // PROPERTY MEDIA API CALL
    useEffect(() => {
        dispatch(mediaImageList(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
    }, [value])





    // FLOOR PLAN DOCUMENT API CALL
    useEffect(() => {
        const params = {
            pageNumber: floorPlanPage,
            PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
            FieldId: 2,
        }
        dispatch(floorPlanDocListApi(token, params))
    }, [floorPlanPage, value])

    // PROPERTY DOCUMENT LIST API CALL
    useEffect(() => {
        const params = {
            pageNumber: pageDocumentList,
            PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
            FieldId: 1,
        }
        dispatch(propertyDocumentsListApi(token, params))
    }, [pageDocumentList, value])


    // DELETE PROPERTY DOCUMENT API CALL
    const deletePropertyDocApiCall = () => {

        dispatch(deletePropertyDocumentsListApi(token, propertySelectedDocument))
            .then((response) => {
                setPageDocumentList(1)
                const params = {
                    pageNumber: page,
                    PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                    FieldId: 1,
                }
                dispatch(propertyDocumentsListApi(token, params))
                setpropertyPopupOpen(false);


            })
            .catch((error) => {


            });


    }

    // DELETE FLOOR PLAN DOCUMENT API CALL
    const deleteFloorPropertyDocApiCall = () => {

        dispatch(deletePropertyDocumentsListApi(token, floorPlanSelectedId))
            .then((response) => {
                setfloorPlanPage(1)
                const params = {
                    pageNumber: 1,
                    PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                    FieldId: 2,
                }
                dispatch(floorPlanDocListApi(token, params))
                    .then((response) => {
                        setfloorPlanPopup(false);



                    })
                    .catch((error) => {


                    });


            })
            .catch((error) => {


            });


    }
    const handleChipClick = (chip) => {
        const isSelected = selectedChips?.includes(chip);

        if (isSelected) {
            setSelectedChips(selectedChips?.filter((selectedChip) => selectedChip !== chip));
        } else {
            setSelectedChips([...selectedChips, chip]);
        }
    };


    const pageHandleChange = (event, value) => {
        setfloorPlanPage(value);
    };

    const pageHandleChangeDocumentList = (event, value) => {
        setPageDocumentList(value);
    };





    const validatePropertyType = (value) => {
        if (propertyMode?.mode == 1 && getPropertyData?.SubscriptionPlanId != null) {
            if (value.length === 0) {
                setPropertyTypeError(t('propertyRequired'));
            } else {
                setPropertyTypeError('');
            }
        }

    };
    const validateSize = (value) => {
        if (propertyMode?.mode == 1 && getPropertyData?.SubscriptionPlanId != null) {
            if (value.length === 0) {
                setSizeError(t('sizeRequired'));
            } else {
                setSizeError('');
            }
        }
    };



    // FLOOR PLAN UPLOAD API CALL
    const handleFloorPlanUpload = async (e) => {
        setLoading(true)
        const file = e.target.files[0];

        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain'];

        if (!allowedTypes.includes(file?.type)) {
            setLoading(false)

            alert('Please upload a JPG, JPEG, PNG, DOC, PDF,TXT or XLSX file.')

            e.target.value = null;
            // Trigger an additional event to force recognition of the change

            e.target.dispatchEvent(new Event('input'));

            return; // Exit the function if file type is not allowed

        }
        try {
            // Create a FormData object
            const formData = new FormData();

            // Add form fields to the FormData object
            formData.append('file', file);
            formData.append('PropertyId', propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id);
            formData.append('FieldId', 2);

            const response = await axios.post(
                `${config.baseURL}/Property/UploadFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type to FormData
                        Authorization: `Bearer ${token}`,
                        // Add any other headers if needed
                    },
                }
            );

            // Handle the response here (e.g., update state or perform other actions)

            if (response.data.Status == 'OK') {
                e.target.value = null;

                e.target.dispatchEvent(new Event('input'));
                const params = {
                    pageNumber: 1,
                    PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                    FieldId: 2,
                }
                dispatch(floorPlanDocListApi(token, params))
                    .then(() => {
                        setLoading(false);
                        toast.success("File Uploaded Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
                    .catch(error => {
                        setLoading(false)
                    })



            } else {

            }

        } catch (error) {


        }

    };




    const validateMonthlyPrice = (value) => {
        if (propertyMode?.mode == 1 && getPropertyData?.SubscriptionPlanId != null) {
            if (value.trim().length === 0) {
                setMonthlyPriceError(t('monthlyPriceRequired'));
            } else {
                setMonthlyPriceError('');
            }
        }
    };




    const validateTitle = (value) => {
        if (value.trim().length === 0) {
            setTitleError(t('titleRequired'));
        } else {
            setTitleError('');
        }
    };

    const validateAddress2 = (value) => {
        if (propertyMode?.mode == 1 && getPropertyData?.SubscriptionPlanId != null) {
            if (!value) {
                setcityAddressError('City is required');
            } else {
                setcityAddressError('');
            }
        }
    };

    const validateLanguage = (value) => {
        if (value.length === 0) {
            setLanguageError(t('languageRequired'));
        } else {
            setLanguageError('');
        }
    };

    const validateDescription = (value) => {
        if (propertyMode?.mode == 1 && getPropertyData?.SubscriptionPlanId != null) {
            if (value.trim().length === 0) {
                setDescriptionError(t('descriptionRequired'));
            } else {
                setDescriptionError('');
            }
        }
    };






    const validateReferences = (value) => {
        if (propertyMode?.mode == 1 && getPropertyData?.SubscriptionPlanId != null) {
            if (value.trim().length === 0) {
                setReferencesError(t('referenceRequired'));
            } else {
                setReferencesError('');
            }
        }
    };
    const validateAssignedUser = (value) => {
        if (value.length === 0) {
            setAssignedUserError(t('assignedRequired'));
        } else {
            setAssignedUserError('');
        }
    };


    const validateStartDate = (value) => {
        if (value.length === 0) {
            setStartDateError('Start Date is Required');
        } else {
            setStartDateError('');
        }
    };

    const validateExpiryDate = (value) => {
        if (value.length === 0) {
            setExpiryDateError('Expiry Date is Required');
        } else {
            setExpiryDateError('');
        }
    };

    const validateAndFormatDate = (dateString) => {
        // Check if the date is in dd-mm-yyyy format
        const isDDMMYYYYFormat = /^\d{2}-\d{2}-\d{4}$/.test(dateString);

        // If it's in dd-mm-yyyy format, convert to yyyy-mm-dd
        if (isDDMMYYYYFormat) {
            const [day, month, year] = dateString.split('-');
            const convertedDate = `${year}-${month}-${day}`;
            return convertedDate;
        }

        // If it's not in dd-mm-yyyy format, return the original date
        return dateString;
    };

    // ONCLICK SAVE BUTTON CREATE AND UPDATE PROPERTY 
    const propertyDetailsApi = () => {
        let validate = false
        if (propertyOfflineData.propertyType == 'create' && propertyMode?.mode == 0) {

            if (title.trim().length == 0) {
                validate = true
                setTitleError(t('titleRequired'));
            } else {
                setTitleError('');
            }

            if (validate == true) {
                // Find the topmost mandatory field that is not filled
                let topmostFieldRef;

                if (field4Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field4Ref;
                }

                // Add more conditions for other mandatory fields as needed

                // Scroll to the topmost mandatory field
                if (topmostFieldRef && topmostFieldRef.current) {
                    scroller.scrollTo(topmostFieldRef.current.id, {
                        smooth: true,
                        offset: -100, // Adjust as needed
                    });
                }

                return;
            }

        } else if (propertyOfflineData.propertyType != 'create' && propertyMode?.mode == 1 && getPropertyData?.SubscriptionPlanId == null) {

            if (title.trim().length == 0) {
                validate = true
                setTitleError(t('titleRequired'));
            } else {
                setTitleError('');
            }

            if (validate == true) {
                // Find the topmost mandatory field that is not filled
                let topmostFieldRef;

                if (field4Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field4Ref;
                }

                // Add more conditions for other mandatory fields as needed

                // Scroll to the topmost mandatory field
                if (topmostFieldRef && topmostFieldRef.current) {
                    scroller.scrollTo(topmostFieldRef.current.id, {
                        smooth: true,
                        offset: -100, // Adjust as needed
                    });
                }

                return;
            }

        }

        else {

            if (propertyType == '') {
                validate = true
                setPropertyTypeError(t('propertyRequired'));
            } else {
                setPropertyTypeError('');
            }

            if (size == '') {
                validate = true
                setSizeError(t('sizeRequired'));
            } else {
                setSizeError('');
            }

            if (monthlyPrice == '') {
                validate = true
                setMonthlyPriceError(t('monthlyPriceRequired'));
            } else {
                setMonthlyPriceError('');
            }


            if (prAddressSelected == '' || prAddressSelected == null) {
                validate = true
                setcityAddressError('City is Required');
            } else {
                setcityAddressError('');
            }
            if (title.trim().length == 0) {
                validate = true
                setTitleError(t('titleRequired'));
            } else {
                setTitleError('');
            }

            if (language == '') {
                validate = true
                setLanguageError(t('languageRequired'));
            } else {
                setLanguageError('');
            }
            if (description.trim().length == 0) {
                validate = true
                setDescriptionError(t('descriptionRequired'));
            } else {
                setDescriptionError('');
            }

            if (isExclusive == true) {
                if (startDate == '') {
                    validate = true
                    setStartDateError('Start Date is Required');
                } else {
                    setStartDateError('');
                }

                if (expiryDate == '') {
                    validate = true
                    setExpiryDateError('Expiry Date is Required');
                } else {
                    setExpiryDateError('');
                }

            }
            if (references.trim().length == 0) {
                validate = true
                setReferencesError(t('referenceRequired'));
            } else {
                setReferencesError('');
            }

            if (validate == true) {
                // Find the topmost mandatory field that is not filled
                let topmostFieldRef;
                if (!propertyType) {
                    topmostFieldRef = field2Ref;
                }
                else if (!field2Ref.current.value) {
                    topmostFieldRef = field2Ref;
                }
                else if (!field3Ref.current.value) {
                    topmostFieldRef = field3Ref;
                }
                else if (field4Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field4Ref;
                }
                else if (!language) {
                    topmostFieldRef = field4Ref;
                }
                else if (field6Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field6Ref;
                }
                else if (field7Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field7Ref;
                }

                else if (!prAddressSelected) {
                    topmostFieldRef = field8Ref;
                }


                // Add more conditions for other mandatory fields as needed

                // Scroll to the topmost mandatory field
                if (topmostFieldRef && topmostFieldRef.current) {
                    scroller.scrollTo(topmostFieldRef.current.id, {
                        smooth: true,
                        offset: -100, // Adjust as needed
                    });
                }

                return;
            }
        }

        if (validate == false) {
            setLoading(true)
            if (propertyOfflineData.propertyType == 'create' && propertyMode?.mode == 0) {
                const params = {
                    CategoryId: residentCategory == "" ? null : parseInt(residentCategory),
                    OfferingTypeId: rentalCategory == "" ? null : parseInt(rentalCategory),
                    PropertytypeId: propertyType == "" ? null : propertyType,
                    Size: size == "" ? 0 : parseInt(size),
                    Noofbathrooms: noOfBathrooms == "" ? 0 : noOfBathrooms,
                    Noofbedrooms: noOfBedrooms === "" ? 0 : noOfBedrooms,
                    Waternelectricity: waterAndElectricity == "" || residentCategory == 1 || rentalCategory == 2 ? null : waterAndElectricity,
                    Noofparking: noOfParkingSpace == "" ? null : parseInt(noOfParkingSpace),
                    FurnishingtypeId: furnishingType == "" ? null : furnishingType,
                    PropertytenureId: tenureOfTheProperty == "" ? null : tenureOfTheProperty,
                    Totalplotsize: totalPlotSize == "" ? null : parseInt(totalPlotSize),
                    OccupancytypeId: occupancy == "" ? null : occupancy,
                    RenovationtypeId: renovationType == "" ? null : renovationType,
                    Views: views == "" ? null : views,
                    MonthlyPrice: monthlyPrice == "" ? 0 : parseInt(monthlyPrice),
                    Title: title == "" ? null : title,
                    Description: description == "" ? null : JSON.stringify(description),
                    LanguageId: language == "" ? null : language,
                    Amenities: selectAminitiesSelected == [] ? null : selectAminitiesSelected?.map(item => item?.Id),
                    Reference: references == "" ? null : references,
                    AssignedtouserId: assignedUser == "" ? null : assignedUser,
                    OwnerId: owner == "" ? null : owner,
                    AvailabilitytypeId: selectedAvailabilityId == "" ? null : selectedAvailabilityId,
                    Isexclusive: isExclusive == "" ? null : isExclusive,
                    AvailableFrom: availableFrom == '' || selectedAvailabilityId != 1 ? null : validateAndFormatDate(availableFrom),
                    ExclusiveStartDate: startDate == '' || isExclusive == false ? null : validateAndFormatDate(startDate),
                    ExclusiveEndDate: expiryDate == '' || isExclusive == false ? null : validateAndFormatDate(expiryDate),
                    CountryId: countryId == '' ? null : countryId,
                    StateId: stateId == '' ? null : stateId,
                    CityId: cityId == '' ? null : cityId,
                    Address: prAddressSelected == '' ? null : prAddressSelected,
                    Lat: locationCoordinates == "" ? null : locationCoordinates?.lat,
                    Lng: locationCoordinates == "" ? null : locationCoordinates?.lng,

                }



                dispatch(addPropertyApi(token, params))
                    .then((response) => {
                        dispatch(propertyModeApi(1))
                        setLoading(false)

                        dispatch(notesListPropertyApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                        dispatch(mediaImageList(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                            .then((response) => {
                                const params = {
                                    pageNumber: 1,
                                    PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                                    FieldId: 1,
                                }
                                dispatch(propertyDocumentsListApi(token, params))
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    if (ownerPropertyEdit?.mode == 3) {
                                        navigate('/OwnerDetail', { state: 12 })
                                    } else {
                                        navigate('/PropertyList')
                                    }



                                }, 1000);



                            })


                    })
                    .catch((error) => {
                        setLoading(false)
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    });
            } else {

                const params = {
                    Id: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                    CategoryId: residentCategory == "" ? null : parseInt(residentCategory),
                    OfferingTypeId: rentalCategory == "" ? null : parseInt(rentalCategory),
                    PropertytypeId: propertyType == "" ? null : propertyType,
                    Size: size == "" ? 0 : parseInt(size),
                    Noofbedrooms: noOfBedrooms === "" ? 0 : noOfBedrooms,
                    Waternelectricity: waterAndElectricity == "" || residentCategory == 1 || rentalCategory == 2 ? null : waterAndElectricity,
                    Noofbathrooms: noOfBathrooms == "" ? 0 : noOfBathrooms,
                    Noofparking: noOfParkingSpace == "" ? null : parseInt(noOfParkingSpace),
                    FurnishingtypeId: furnishingType == "" ? null : furnishingType,
                    PropertytenureId: tenureOfTheProperty == "" ? null : tenureOfTheProperty,
                    Totalplotsize: totalPlotSize == "" ? null : parseInt(totalPlotSize),
                    OccupancytypeId: occupancy == "" ? null : occupancy,
                    RenovationtypeId: renovationType == "" ? null : renovationType,
                    Views: views == "" ? null : views,
                    MonthlyPrice: monthlyPrice == "" ? 0 : parseInt(monthlyPrice),
                    Title: title == "" ? null : title,
                    Description: description == "" ? null : JSON.stringify(description),
                    LanguageId: language == "" ? null : language,
                    Amenities: selectAminitiesSelected == [] ? null : selectAminitiesSelected?.map(item => item?.Id),
                    Reference: references == "" ? null : references,
                    AssignedtouserId: assignedUser == "" ? null : assignedUser,
                    OwnerId: owner == "" ? null : owner,
                    AvailabilitytypeId: selectedAvailabilityId == "" ? null : selectedAvailabilityId,
                    Isexclusive: isExclusive == "" ? null : isExclusive,
                    AvailableFrom: availableFrom == '' || selectedAvailabilityId != 1 ? null : validateAndFormatDate(availableFrom),
                    ExclusiveStartDate: startDate == '' || isExclusive == false ? null : validateAndFormatDate(startDate),
                    ExclusiveEndDate: expiryDate == '' || isExclusive == false ? null : validateAndFormatDate(expiryDate),
                    CountryId: countryId == '' ? null : countryId,
                    StateId: stateId == '' ? null : stateId,
                    CityId: cityId == '' ? null : cityId,
                    Address: prAddressSelected == '' ? null : prAddressSelected,
                    Lat: locationCoordinates == "" ? null : locationCoordinates?.lat,
                    Lng: locationCoordinates == "" ? null : locationCoordinates?.lng,
                    IsBoosterAutoRenew: autoRenew
                }

                console.log('final', params)

                dispatch(updatePropertyApi(token, params))
                    .then((response) => {
                        setLoading(false)
                        dispatch(notesListPropertyApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                        dispatch(mediaImageList(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                            .then((response) => {
                                const params = {
                                    pageNumber: 1,
                                    PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                                    FieldId: 1,
                                }
                                dispatch(propertyDocumentsListApi(token, params))
                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    if (ownerPropertyEdit?.mode == 3) {
                                        navigate('/OwnerDetail', { state: 12 })
                                    } else {
                                        navigate('/PropertyList')
                                    }


                                }, 1000);


                            })


                    })
                    .catch((error) => {
                        const params = {
                            pageNumber: 1,
                            PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                            FieldId: 1,
                        }
                        toast.error("This property does not exist", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setTimeout(() => {
                            setLoading(false)
                            if (ownerPropertyEdit?.mode == 3) {
                                navigate('/OwnerDetail', { state: 12 })
                            } else {
                                navigate('/PropertyList')
                            }


                        }, 1000);

                    });
            }

        }
        // Check if both username and password are entered

    };


    const nextTab = () => {


        let validate = false
        if (propertyOfflineData.propertyType == 'create' && propertyMode?.mode == 0) {

            if (title.trim().length == 0) {
                validate = true
                setTitleError(t('titleRequired'));
            } else {
                setTitleError('');
            }

            if (validate == true) {
                // Find the topmost mandatory field that is not filled
                let topmostFieldRef;

                if (field4Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field4Ref;
                }

                // Add more conditions for other mandatory fields as needed

                // Scroll to the topmost mandatory field
                if (topmostFieldRef && topmostFieldRef.current) {
                    scroller.scrollTo(topmostFieldRef.current.id, {
                        smooth: true,
                        offset: -100, // Adjust as needed
                    });
                }

                return;
            }

        }

        else if (propertyOfflineData.propertyType != 'create' && propertyMode?.mode == 1 && getPropertyData?.SubscriptionPlanId == null) {

            if (title.trim().length == 0) {
                validate = true
                setTitleError(t('titleRequired'));
            } else {
                setTitleError('');
            }

            if (validate == true) {
                // Find the topmost mandatory field that is not filled
                let topmostFieldRef;

                if (field4Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field4Ref;
                }

                // Add more conditions for other mandatory fields as needed

                // Scroll to the topmost mandatory field
                if (topmostFieldRef && topmostFieldRef.current) {
                    scroller.scrollTo(topmostFieldRef.current.id, {
                        smooth: true,
                        offset: -100, // Adjust as needed
                    });
                }

                return;
            }

        }
        else {

            if (propertyType == '') {
                validate = true
                setPropertyTypeError(t('propertyRequired'));
            } else {
                setPropertyTypeError('');
            }

            if (size == '') {
                validate = true
                setSizeError(t('sizeRequired'));
            } else {
                setSizeError('');
            }

            if (monthlyPrice == '') {
                validate = true
                setMonthlyPriceError(t('monthlyPriceRequired'));
            } else {
                setMonthlyPriceError('');
            }


            if (prAddressSelected == '' || prAddressSelected == null) {
                validate = true
                setcityAddressError('City is Required');
            } else {
                setcityAddressError('');
            }
            if (title.trim().length == 0) {
                validate = true
                setTitleError(t('titleRequired'));
            } else {
                setTitleError('');
            }

            if (language == '') {
                validate = true
                setLanguageError(t('languageRequired'));
            } else {
                setLanguageError('');
            }
            if (description.trim().length == 0) {
                validate = true
                setDescriptionError(t('descriptionRequired'));
            } else {
                setDescriptionError('');
            }

            if (isExclusive == true) {
                if (startDate == '') {
                    validate = true
                    setStartDateError('Start Date is Required');
                } else {
                    setStartDateError('');
                }

                if (expiryDate == '') {
                    validate = true
                    setExpiryDateError('Expiry Date is Required');
                } else {
                    setExpiryDateError('');
                }

            }
            if (references.trim().length == 0) {
                validate = true
                setReferencesError(t('referenceRequired'));
            } else {
                setReferencesError('');
            }

            if (validate == true) {
                // Find the topmost mandatory field that is not filled
                let topmostFieldRef;
                if (!propertyType) {
                    topmostFieldRef = field2Ref;
                }
                else if (!field2Ref.current.value) {
                    topmostFieldRef = field2Ref;
                }
                else if (!field3Ref.current.value) {
                    topmostFieldRef = field3Ref;
                }
                else if (field4Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field4Ref;
                }
                else if (!language) {
                    topmostFieldRef = field4Ref;
                }
                else if (field6Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field6Ref;
                }
                else if (field7Ref.current.value.trim().length == 0) {
                    topmostFieldRef = field7Ref;
                }

                else if (!prAddressSelected) {
                    topmostFieldRef = field8Ref;
                }


                // Add more conditions for other mandatory fields as needed

                // Scroll to the topmost mandatory field
                if (topmostFieldRef && topmostFieldRef.current) {
                    scroller.scrollTo(topmostFieldRef.current.id, {
                        smooth: true,
                        offset: -100, // Adjust as needed
                    });
                }

                return;
            }
        }


        if (validate == false) {


            setLoading(true)
            if (propertyOfflineData.propertyType == 'create' && propertyMode?.mode == 0) {

                const params = {
                    CategoryId: residentCategory == "" ? null : parseInt(residentCategory),
                    OfferingTypeId: rentalCategory == "" ? null : parseInt(rentalCategory),
                    PropertytypeId: propertyType == "" ? null : propertyType,
                    Size: size == "" ? 0 : parseInt(size),
                    Noofbedrooms: noOfBedrooms === "" ? 0 : noOfBedrooms,
                    Waternelectricity: waterAndElectricity == "" || residentCategory == 1 || rentalCategory == 2 ? null : waterAndElectricity,
                    Noofbathrooms: noOfBathrooms == "" ? 0 : noOfBathrooms,
                    Noofparking: noOfParkingSpace == "" ? null : parseInt(noOfParkingSpace),
                    FurnishingtypeId: furnishingType == "" ? null : furnishingType,
                    PropertytenureId: tenureOfTheProperty == "" ? null : tenureOfTheProperty,
                    Totalplotsize: totalPlotSize == "" ? null : parseInt(totalPlotSize),
                    OccupancytypeId: occupancy == "" ? null : occupancy,
                    RenovationtypeId: renovationType == "" ? null : renovationType,
                    Views: views == "" ? null : views,
                    MonthlyPrice: monthlyPrice == "" ? 0 : parseInt(monthlyPrice),
                    Title: title == "" ? null : title,
                    Description: description == "" ? null : JSON.stringify(description),
                    LanguageId: language == "" ? null : language,
                    Amenities: selectAminitiesSelected == [] ? null : selectAminitiesSelected?.map(item => item?.Id),
                    Reference: references == "" ? null : references,
                    AssignedtouserId: assignedUser == "" ? null : assignedUser,
                    OwnerId: owner == "" ? null : owner,
                    AvailabilitytypeId: selectedAvailabilityId == "" ? null : selectedAvailabilityId,
                    Isexclusive: isExclusive == "" ? null : isExclusive,
                    AvailableFrom: availableFrom == '' || selectedAvailabilityId != 1 ? null : validateAndFormatDate(availableFrom),
                    ExclusiveStartDate: startDate == '' || isExclusive == false ? null : validateAndFormatDate(startDate),
                    ExclusiveEndDate: expiryDate == '' || isExclusive == false ? null : validateAndFormatDate(expiryDate),
                    CountryId: countryId == '' ? null : countryId,
                    StateId: stateId == '' ? null : stateId,
                    CityId: cityId == '' ? null : cityId,
                    Address: prAddressSelected == '' ? null : prAddressSelected,
                    Lat: locationCoordinates == "" ? null : locationCoordinates?.lat,
                    Lng: locationCoordinates == "" ? null : locationCoordinates?.lng,


                }
                dispatch(addPropertyApi(token, params))
                    .then((response) => {

                        dispatch(propertyModeApi(1))
                        dispatch(notesListPropertyApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                        dispatch(mediaImageList(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                            .then((response) => {
                                const params = {
                                    pageNumber: 1,
                                    PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                                    FieldId: 1,
                                }
                                dispatch(propertyDocumentsListApi(token, params))


                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    const params = {
                                        pageNumber: floorPlanPage,
                                        PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                                        FieldId: 2,
                                    }
                                    dispatch(floorPlanDocListApi(token, params))
                                    setUrlLink_360('')
                                    setVideoTourUrl('')
                                    setLoading(false)
                                    setValue('two')
                                }, 1000);




                            })

                    })
                    .catch((error) => {
                        setLoading(false)
                        toast.error(error, {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });

                    });
            } else {
                const params = {
                    Id: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                    CategoryId: residentCategory == "" ? null : parseInt(residentCategory),
                    OfferingTypeId: rentalCategory == "" ? null : parseInt(rentalCategory),
                    PropertytypeId: propertyType == "" ? null : propertyType,
                    Size: size == "" ? 0 : parseInt(size),
                    Noofbedrooms: noOfBedrooms === "" ? 0 : noOfBedrooms,
                    Waternelectricity: waterAndElectricity == "" || residentCategory == 1 || rentalCategory == 2 ? null : waterAndElectricity,
                    Noofbathrooms: noOfBathrooms == "" ? 0 : noOfBathrooms,
                    Noofparking: noOfParkingSpace == "" ? null : parseInt(noOfParkingSpace),
                    FurnishingtypeId: furnishingType == "" ? null : furnishingType,
                    PropertytenureId: tenureOfTheProperty == "" ? null : tenureOfTheProperty,
                    Totalplotsize: totalPlotSize == "" ? null : parseInt(totalPlotSize),
                    OccupancytypeId: occupancy == "" ? null : occupancy,
                    RenovationtypeId: renovationType == "" ? null : renovationType,
                    Views: views == "" ? null : views,
                    MonthlyPrice: monthlyPrice == "" ? 0 : parseInt(monthlyPrice),
                    Title: title == "" ? null : title,
                    Description: description == "" ? null : JSON.stringify(description),
                    LanguageId: language == "" ? null : language,
                    Amenities: selectAminitiesSelected == [] ? null : selectAminitiesSelected?.map(item => item?.Id),
                    Reference: references == "" ? null : references,
                    AssignedtouserId: assignedUser == "" ? null : assignedUser,
                    OwnerId: owner == "" ? null : owner,
                    AvailabilitytypeId: selectedAvailabilityId == "" ? null : selectedAvailabilityId,
                    Isexclusive: isExclusive == "" ? null : isExclusive,
                    AvailableFrom: availableFrom == '' || selectedAvailabilityId != 1 ? null : validateAndFormatDate(availableFrom),
                    ExclusiveStartDate: startDate == '' || isExclusive == false ? null : validateAndFormatDate(startDate),
                    ExclusiveEndDate: expiryDate == '' || isExclusive == false ? null : validateAndFormatDate(expiryDate),
                    CountryId: countryId == '' ? null : countryId,
                    StateId: stateId == '' ? null : stateId,
                    CityId: cityId == '' ? null : cityId,
                    Address: prAddressSelected == '' ? null : prAddressSelected,
                    Lat: locationCoordinates == "" ? null : locationCoordinates?.lat,
                    Lng: locationCoordinates == "" ? null : locationCoordinates?.lng,
                    IsAutoRenew: autoRenew

                }

                dispatch(updatePropertyApi(token, params))
                    .then((response) => {
                        dispatch(notesListPropertyApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                        dispatch(mediaImageList(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                            .then((response) => {
                                const params = {
                                    pageNumber: 1,
                                    PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                                    FieldId: 1,
                                }
                                dispatch(propertyDocumentsListApi(token, params))

                                toast.success("Record Saved Successfully", {
                                    position: 'top-right',
                                    autoClose: 1000, // Adjust the duration as needed
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                                setTimeout(() => {
                                    setLoading(false)
                                    setValue('two')
                                }, 1000);



                            })

                    })
                    .catch((error) => {
                        const params = {
                            pageNumber: 1,
                            PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                            FieldId: 1,
                        }
                        toast.error("This property does not exist", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setTimeout(() => {
                            setLoading(false)
                            
                                navigate('/PropertyList')

                        }, 1000);

                    });
            }

        }
        // Check if both username and password are entered

    };
    //State variables and validation for Media




    // ONCLICK MEDIA NEXT BUTTON
    const nextMediaPage = () => {
        dispatch(notesListPropertyApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
        toast.success("Record Saved Successfully", {
            position: 'top-right',
            autoClose: 1000, // Adjust the duration as needed
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
        setTimeout(() => {
            const params = {
                pageNumber: floorPlanPage,
                PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                FieldId: 2,
            }
            dispatch(floorPlanDocListApi(token, params))
            setLoading(false)
            setValue('three')
        }, 1000);

        const params = {
            pageNumber: 1,
            PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
            FieldId: 1,
        }
        dispatch(propertyDocumentsListApi(token, params))
    }

    // ONCLICK MEDIA API CALL
    const mediaApi = () => {
        setLoading(true)
        const params = {
            PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
            VideoTourUrl: videoTourUrl == "" ? null : videoTourUrl,
            ThreeSixtyUrlLink: urlLink_360 == "" ? null : urlLink_360
        }
        dispatch(uploadVideoUrlApi(token, params))
            .then((response) => {
                toast.success("Record Saved Successfully", {
                    position: 'top-right',
                    autoClose: 1000, // Adjust the duration as needed
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setLoading(false)

            })
            .catch((error) => {
                setLoading(false)


            });

    };






    const validateNotes = (value) => {
        if (value.length === 0) {
            setNotesError(t('NotesRequired'));
        } else {
            setNotesError('');
        }
    };


    const noteNextTab = () => {
        const params = {
            pageNumber: 1,
            PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
            FieldId: 1,
        }
        dispatch(propertyDocumentsListApi(token, params))
        setValue('four')
    }

    // NOTES API CALL
    const NotesApi = () => {

        let validate = false
        if (notes == '') {
            validate = true
            setNotesError(t('NotesRequired'));
        } else {
            setNotesError('');
        }


        if (validate == false) {
            setLoading(true)
            const params = {
                PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                Note: notes
            }
            dispatch(addNotesApi(token, params))
                .then((response) => {
                    dispatch(notesListPropertyApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                        .then((response) => {
                            toast.success("Record Saved Successfully", {
                                position: 'top-right',
                                autoClose: 1000, // Adjust the duration as needed
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                            setTimeout(() => {
                                setLoading(false)
                                setNotes('')
                            }, 1000);

                        })




                })
                .catch((error) => {
                    setLoading(false)


                });
        }
        // Check if both username and password are entered

    };



    // DOCUMENT API CALL
    const DocumentsApi = (e) => {
        const scrollPosition = window.scrollY;
        e.preventDefault();
        window.scrollTo(0, scrollPosition);

        dispatch(notesListPropertyApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
        if (ownerPropertyEdit?.mode == 3) {
            navigate('/OwnerDetail', { state: 12 })
        } else {
            navigate('/PropertyList')
        }
        // Check if both username and password are entered

    };


    // PROPERTY MEDIA UPLOAD 
    const handleMediaFileChange = async (e) => {
        const files = e.target.files;
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const maxFiles = 35; // Maximum allowed files

        try {
            // Calculate the total number of images from both arrays
            const totalImages = imageMediaListData?.length + amenitiesMediaData?.length + files.length;

            if (totalImages > maxFiles) {
                alert(`You can upload a maximum of ${maxFiles} files.`);
                return;
            }

            const formData = new FormData();
            const propertyId =
                propertyOfflineData?.propertyType === 'create'
                    ? addPropertyData?.PropertyId
                    : getPropertyData?.Id;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                if (!allowedTypes.includes(file.type)) {
                    alert('Please upload JPG, JPEG, or PNG files only.');
                    continue;
                }

                formData.append('files', file);
            }
            setLoading(true);
            formData.append('PropertyId', propertyId);
            formData.append('FieldId', 1);

            const response = await axios.post(
                `${config.baseURL}/Property/UploadMediaImage`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.Status === 'OK') {
                e.target.value = null;
                dispatch(mediaImageList(token, propertyId))
                    .then(() => {
                        toast.success('Files Uploaded Successfully', {
                            position: 'top-right',
                            autoClose: 700,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                    });
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }



    };




    // LOCATION SEARCH API CALL
    const handleCitySearch = async (inputValue) => {
        if (inputValue.length == 0) {
            setcityDataSeachOption([]); // Clear the options when the input is empty
        }
        else if (inputValue.length > 0) {
            try {
                const response = await axios.post(`${config.baseURL}/Location/CitiesSearchAutoComplete?input=${inputValue}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response?.data?.Data; // Adjust this based on your API response structure

                setcityDataSeachOption(data); // Update the options state with the response data
            } catch (error) {
                console.error('Error fetching location data', error);
            }
        }
    };

    const handleCitySelect = async (event, value) => {
        validateAddress2(value)

        setstateId(value?.StateId)
        setcityId(value?.CityId)
        setcountryId(value?.CoutryId)
        setPrAddressSelected(value?.Name)
        let masterData = (`${value?.Name}`)

        try {
            const response = await axios.post(`${config.baseURL}/Location/Autocomplete?input=${masterData}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const tempdata = response?.data?.Data;

            setLocationCoordinates(tempdata)

        } catch (error) {

        }

        // You can perform any further actions with the selected value here
    };



    // DOCUMENT API CALL
    const handleFileChange = async (e) => {
        setLoading(true)
        const file = e.target.files[0];
        const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/plain'];

        if (!allowedTypes.includes(file?.type)) {
            setLoading(false)

            alert('Please upload a JPG, JPEG, PNG, DOC, PDF, TXT or XLSX file.')

            e.target.value = null;
            // Trigger an additional event to force recognition of the change

            e.target.dispatchEvent(new Event('input'));

            return; // Exit the function if file type is not allowed

        }
        try {
            // Create a FormData object
            const formData = new FormData();

            // Add form fields to the FormData object
            formData.append('file', file);
            formData.append('PropertyId', propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id);
            formData.append('FieldId', 1);

            const response = await axios.post(
                `${config.baseURL}/Property/UploadFile`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the content type to FormData
                        Authorization: `Bearer ${token}`,
                        // Add any other headers if needed
                    },
                }
            );

            // Handle the response here (e.g., update state or perform other actions)

            if (response.data.Status == 'OK') {
                e.target.value = null;

                e.target.dispatchEvent(new Event('input'));
                const params = {
                    pageNumber: 1,
                    PropertyId: propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                    FieldId: 1,
                }
                dispatch(propertyDocumentsListApi(token, params))
                    .then(() => {
                        setLoading(false);
                        toast.success("File Uploaded Successfully", {
                            position: 'top-right',
                            autoClose: 1000, // Adjust the duration as needed
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
                    .catch(error => {
                        setLoading(false)
                    })



            } else {

            }

        } catch (error) {
            // Handle any errors here
            console.error('Error:', error);
        }

    };


    function filterArrayById(mainArray, idArray) {
        return mainArray?.filter(item => idArray?.includes(item?.Id));
    }

    // ONLCLICK AMENITIES FILTER
    const onClickAmenities = () => {
        const filteredArray = filterArrayById(propertyMasterData?.Amenities, selectedChips);
        setselectAminitiesSelected(filteredArray)

        setOpen(false)
    }

    const openCloseAccordingList = () => {
        setAccordingList(!accordingList)
    }

    const openCloseSpecificationAccordingList = () => {
        setSpecificationAccordingList(!specificationAccordingList)
    }

    const openClosePricingAccordingList = () => {
        setPricingAccordingList(!pricingAccordingList)
    }

    const openCloseDescriptionAccordingList = () => {
        setDescriptionAccordingList(!descriptionAccordingList)
    }

    const openCloseAmenitiesAccordingList = () => {
        setAmenitiesAccordingList(!amenitiesAccordingList)
    }

    const openClosePropertyAccordingList = () => {
        setPropertyAccordingList(!propertyAccordingList)
    }

    const openCloseFloorAccordingList = () => {
        setFloorAccordingList(!floorAccordingList)
    }

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    const handleChanges = (event, newValue) => { setValue(newValue); };


    const handleFloorClickOpen = (id) => {

        setfloorPlanSelectedId(id)
        setfloorPlanPopup(true);
    };

    const handleFloorClosepopup = () => {

        setfloorPlanPopup(false);
    };
    const handleClickOpen = (id) => {

        setpropertySelectedDocument(id)
        setpropertyPopupOpen(true);
    };

    const handleClosepopup = () => {

        setpropertyPopupOpen(false);
    };

    // ONCLICK FILE DOWNLOAD
    const handleDownload = (item) => {
        // Specify the URL of the file you want to download
        const fileUrl = item?.FileUrl;

        // Specify the desired filename
        const fileName = item?.FileName;

        // Construct the full URL with the filename
        const urlWithFileName = `${config.baseURL}${fileUrl}?filename=${fileName}`;

        // Open a new tab and set its location to the constructed URL
        window.open(urlWithFileName, '_blank');

    };


    // ONLCLICK CREDIT BALANCE CHECK
    const handlePublishOpen = (item) => {
        setLoading(true)
        // setPropertyIdToPublish(getPropertyData?.Id)
        dispatch(checkPublishCreditsApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
            .then((response) => {
                setLoading(false)
                setPublishOpen(true);

            })
            .catch((error) => {
                setLoading(false)
                setPublishOpen(true);

            });
    };
    const handlePublishClose = () => {
        setPublishOpen(false);
    };


    // ONLCLICK UPGRADE PLAN OPEN
    const handleUpgradePlanOpen = (item) => {
        setLoading(true)

        setSubscriptionIdSelectedFeatured(2)
        setSubscriptionIdSelectedPremium(4)
        setMainSubscriptionTypeSelected("")
        setPropertyCurrentSubscriptionPlanId(item)
        dispatch(checkPublishCreditsApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
            .then((response) => {
                setLoading(false)
                setFeaturedAutoRenewCheckBox(true)
                setPremiumAutoRenewCheckBox(true)
                setUpgradePlanOpen(true);

            })
            .catch((error) => {
                setLoading(false)
                setUpgradePlanOpen(true);

            });
    };
    const handleUpgradePlanClose = () => {
        setUpgradePlanOpen(false);
    };

    // ONLCLICK PROPERTY UPGRADE API CALL
    const PublishUpgradeApiCall = async (params) => {
        setLoading(true)
        try {
            const response = await axios.post(`${config.baseURL}/Property/PublishProperty`, params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {

                    dispatch(getPropertyDetailApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                        .then(() => {
                            setLoading(false)
                            handleUpgradePlanClose()
                        })
                        .catch(() => {
                            setLoading(false)
                            handleUpgradePlanClose()
                        })
                } else if (response.data.Status == 'ERROR') {
                    setLoading(false)

                    handleUpgradePlanClose()
                }

            } else {

            }
        } catch (error) {
            toast.error("Property does not exist or it is already upgraded to the selected plan", {
                position: 'top-right',
                autoClose: 1300, // Adjust the duration as needed
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setLoading(false)
            handleUpgradePlanClose()

        }
    };

    // ONLCLICK PROPERTY PUBLISH API CALL
    const publishApiCall = async (params) => {
        setLoading(true)
        try {
            const response = await axios.post(`${config.baseURL}/Property/PublishProperty`, params, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status == 200) {

                if (response.data.Status == 'OK') {
                    dispatch(getPropertyDetailApi(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))

                        .then(() => {
                            setLoading(false)
                            handlePublishClose()
                        })
                        .catch(() => {
                            setLoading(false)
                            handlePublishClose()
                        })
                } else if (response.data.Status == 'ERROR') {
                    setLoading(false)

                    handlePublishClose()
                }

            } else {

            }
        } catch (error) {
            toast.error("Property does not exist or is already published or has already been requested for approval to publish", {
                position: 'top-right',
                autoClose: 1300, // Adjust the duration as needed
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
          
            setLoading(false)
            handlePublishClose()

        }
    };

    // ONLCLICK UPGRADE PLAN
    const onClickPublishUpgrade = () => {
        if (mainSubscriptionTypeSelected == 1) {
            if (subscriptionIdSelectedFeatured == 2) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForFeatured_15_Days == 1) {
                    const params = {
                        "PropertyId": propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                        "SubscriptionPlanId": subscriptionIdSelectedFeatured,
                        "IsBoosterAutoRenew": featuredAutoRenewCheckBox
                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    toast.error("Insufficient Balance", {
                        position: 'top-right',
                        autoClose: 300, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            else if (subscriptionIdSelectedFeatured == 3) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForFeatured_1_Month == 1) {
                    const params = {
                        "PropertyId": propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                        "SubscriptionPlanId": subscriptionIdSelectedFeatured,
                        "IsBoosterAutoRenew": featuredAutoRenewCheckBox,
                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    toast.error("Insufficient Balance", {
                        position: 'top-right',
                        autoClose: 300, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }

            }
        }
        else if (mainSubscriptionTypeSelected == 2) {
            if (subscriptionIdSelectedPremium == 4) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsForPremium_15_Days == 1) {
                    const params = {
                        "PropertyId": propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                        "SubscriptionPlanId": subscriptionIdSelectedPremium,
                        "IsBoosterAutoRenew": premiumAutoRenewCheckBox

                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    toast.error("Insufficient Balance", {
                        position: 'top-right',
                        autoClose: 300, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
            else if (subscriptionIdSelectedPremium == 5) {
                if (checkPublishCreditsData?.IsSuffiecientCreditsPremium_1_Month == 1) {
                    const params = {
                        "PropertyId": propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                        "SubscriptionPlanId": subscriptionIdSelectedPremium,
                        "IsBoosterAutoRenew": premiumAutoRenewCheckBox
                    }
                    PublishUpgradeApiCall(params)
                }
                else {
                    toast.error("Insufficient Balance", {
                        position: 'top-right',
                        autoClose: 300, // Adjust the duration as needed
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    // ONCLICK PUBLISH MODE
    const onClickPublish = () => {
        if (checkPublishCreditsData?.IsSuffiecientCreditsForStandard == 1) {
            const params = {
                "PropertyId": propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id,
                "SubscriptionPlanId": 1
            }
            publishApiCall(params)
        }
        else {
            toast.error("Insufficient Balance", {
                position: 'top-right',
                autoClose: 300, // Adjust the duration as needed
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }


    // ONLCLICK PROPERTY MEDIA DELETE
    const onClickMediaImageDelete = (imageId) => {
        setLoading(true)
        dispatch(deleteMediaImageApi(token, imageId))
            .then((response) => {
                toast.success("Deleted Image Successfully", {
                    position: 'top-right',
                    autoClose: 500, // Adjust the duration as needed
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                dispatch(mediaImageList(token, propertyOfflineData?.propertyType == 'create' ? addPropertyData?.PropertyId : getPropertyData?.Id))
                setTimeout(() => {
                    setLoading(false)
                }, 1000);
            })
            .catch((error) => {
                setLoading(false)


            });
    }




    const handleChangeAutoRenew = () => {
        setAutoRenew(!autoRenew)

    };
    return (
        <div className="App">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={propertyPopupOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClosepopup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleClosepopup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this document ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleClosepopup}>Cancel</Button>
                    <Button onClick={() => deletePropertyDocApiCall()}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={floorPlanPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleFloorClosepopup}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box_popup'
            >

                <DialogContent class="dialog_box_block">
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_title">
                        <h6>Delete</h6>
                        <Button onClick={handleFloorClosepopup}><img src={closeModal} /></Button>
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" class="dialog_box_detail">
                        Are you sure you want to delete this document ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions class="dialog_box_button">
                    <Button onClick={handleFloorClosepopup}>Cancel</Button>
                    <Button onClick={() => deleteFloorPropertyDocApiCall()}>Delete</Button>
                </DialogActions>
            </Dialog>


            <div class="overlay"></div>

            <Topbar />

            <Sidebar message={'Properties'} />

            <section class="content">
                <div class="block-header" id="fieldDefaultTop" ref={fieldDefaultRefTop}>
                    <h2>{t('propertyDetails')}</h2>

                </div>

                <div class="row clearfix">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div class="tab_block" >
                            <Tabs
                                value={value}
                                onChange={handleChanges}
                                className='tab-nav-right'
                                aria-label="wrapped label tabs example"
                            >
                                <Tab className='tabs_title' value="one" label={t('propertyDetails')} wrapped />
                                <Tab className='tabs_title' value="two" label={<div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>Property Media</span>
                                    <CustomBadge content={propertyMode?.mode == 0 ? 0 : imageMediaListData?.length} color="#FDB51F" fontSize="1rem" size="1.8rem" style={{ marginLeft: '0.5rem' }} />
                                </div>} disabled={propertyMode?.mode == 0 ? true : false} />

                                <Tab className='tabs_title' value="three" label={t('notes')} disabled={propertyMode?.mode == 0 ? true : false} />
                                <Tab className='tabs_title' value="four" label={t('documents')} disabled={propertyMode?.mode == 0 ? true : false} />
                            </Tabs>
                        </div>

                        <div class="tab_content_block">
                            {/* <!-- Tab panes --> */}
                            {
                                value == 'one' ?
                                    <div class="row clearfix">
                                        <div class="col-sm-9">
                                            <div class="panel-group full-body" id="accordion_3" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_3">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_3">
                                                                <span>{t('type')}</span>
                                                                <i className={accordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {accordingList ?
                                                        <div id="collapseOne_3" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_3">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="row clearfix no-gutter">
                                                                        <div class="col-sm-6">
                                                                            <div class="type_block border_right">
                                                                                <div class="row clearfix">
                                                                                    <div class="col-sm-6" >
                                                                                        <a class={residentCategory == 2 ? "types active" : "types deactive"}
                                                                                            style={{
                                                                                                color: !propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? '#999' : "inherit",// Change color when disabled
                                                                                                pointerEvents: !propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? 'none' : "auto", // Disable pointer events when disabled

                                                                                            }}
                                                                                            onClick={() => { setResidentCategory(2); setPropertyType("") }} >
                                                                                            <img src={residential} />
                                                                                            <h6>{t('residential')}</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <a class={residentCategory == 1 ? "types active" : "types deactive"}
                                                                                            style={{
                                                                                                color: !propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? '#999' : "inherit",// Change color when disabled
                                                                                                pointerEvents: !propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? 'none' : "auto", // Disable pointer events when disabled

                                                                                            }}
                                                                                            onClick={() => { setResidentCategory(1); setPropertyType("") }}>
                                                                                            <img src={commercial} />
                                                                                            <h6>{t('commercial')}</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-6">
                                                                            <div class="type_block">
                                                                                <div class="row clearfix">
                                                                                    <div class="col-sm-6">
                                                                                        <a class={rentalCategory == 1 ? "types active" : "types deactive"}
                                                                                            style={{
                                                                                                color: !propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? '#999' : "inherit",// Change color when disabled
                                                                                                pointerEvents: !propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? 'none' : "auto", // Disable pointer events when disabled

                                                                                            }}
                                                                                            onClick={() => { setRentalCategory(1); setPropertyType("") }} >
                                                                                            <img src={rent} />
                                                                                            <h6>{t('rent')}</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <a class={rentalCategory == 2 ? "types active" : "types deactive"}
                                                                                            style={{
                                                                                                color: !propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? '#999' : "inherit",// Change color when disabled
                                                                                                pointerEvents: !propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? 'none' : "auto", // Disable pointer events when disabled

                                                                                            }}
                                                                                            onClick={() => { setRentalCategory(2); setPropertyType("") }} >
                                                                                            <img src={sale} />
                                                                                            <h6>{t('sale')}</h6>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_4" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_4">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseSpecificationAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_4">
                                                                <span>{t('specification')}</span>
                                                                <i className={specificationAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {specificationAccordingList ?
                                                        <div id="collapseOne_4" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_4">
                                                            {residentCategory == 1 ? (<>
                                                                <div class="panel-body">
                                                                    <div class="card">
                                                                        <div class="form_block">
                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div>
                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                            <Select
                                                                                                className='form-control border_temp'
                                                                                                sx={{ borderColor: 'transparent' }}
                                                                                                ref={field1Ref}
                                                                                                id="field1"
                                                                                                displayEmpty
                                                                                                value={propertyType}
                                                                                                title={t('propertyType')}
                                                                                                onChange={(e) => { setPropertyType(e.target.value); validatePropertyType(e.target.value); setNoOfBedrooms("") }}
                                                                                                input={<OutlinedInput />}

                                                                                                MenuProps={{
                                                                                                    PaperProps: {
                                                                                                        style: {
                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                            width: 250,
                                                                                                        },
                                                                                                    },
                                                                                                    disableScrollLock: true,
                                                                                                }}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >
                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                    <em className='select_text'>{t('propertyType') + '*'}</em>
                                                                                                </MenuItem>
                                                                                                {selectedPropertyTypeDropdown?.map((item) => (
                                                                                                    <MenuItem
                                                                                                        className='drop_menus'
                                                                                                        key={item?.Id}
                                                                                                        value={item?.Id}
                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                    >
                                                                                                        {item?.Name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>
                                                                                            {
                                                                                                propertyTypeError && (
                                                                                                    <span class="validation_notes">{propertyTypeError}</span>

                                                                                                )
                                                                                            }
                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" ref={field2Ref}
                                                                                                id="field2" class="form-control right_space" placeholder={t('size')} value={size} onKeyPress={handleKeyPress} maxLength={6} title="Size" onChange={(e) => { setSize(e.target.value); validateSize(e.target.value) }} />
                                                                                            <span class="form_control_title">sqm</span>
                                                                                            {
                                                                                                sizeError && (
                                                                                                    <span class="validation_notes">{sizeError}</span>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div>
                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                            <Select
                                                                                                className='form-control border_temp'
                                                                                                sx={{ borderColor: 'transparent' }}

                                                                                                displayEmpty
                                                                                                title={t('noBathrooms')}
                                                                                                value={noOfBathrooms}
                                                                                                onChange={(e) => { setNoOfBathrooms(e.target.value); }}
                                                                                                input={<OutlinedInput />}
                                                                                                MenuProps={{
                                                                                                    PaperProps: {
                                                                                                        style: {
                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                            width: 250,
                                                                                                        },
                                                                                                    },
                                                                                                    disableScrollLock: true,
                                                                                                }}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >
                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                    <em className='select_text'>{t('noBathrooms')}</em>
                                                                                                </MenuItem>
                                                                                                {numbers?.map((item) => (
                                                                                                    <MenuItem
                                                                                                        className='drop_menus'
                                                                                                        key={item?.Id}
                                                                                                        value={item?.Id}
                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                    >
                                                                                                        {item?.Name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>

                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" title={'No. of Parking Space'} class="form-control right_space" maxLength={3} placeholder="No. of Parking Space" value={noOfParkingSpace} onKeyPress={handleKeyPress} onChange={(e) => { setNoOfParkingSpace(e.target.value) }} />
                                                                                            <span class="form_control_title">{t('parkings')}</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div>
                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                            <Select
                                                                                                className='form-control border_temp'
                                                                                                sx={{ borderColor: 'transparent' }}

                                                                                                displayEmpty
                                                                                                title={t('furnishingType')}
                                                                                                value={furnishingType}
                                                                                                onChange={(e) => { setFurnishingType(e.target.value); }}
                                                                                                input={<OutlinedInput />}

                                                                                                MenuProps={{
                                                                                                    PaperProps: {
                                                                                                        style: {
                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                            width: 250,
                                                                                                        },
                                                                                                    },
                                                                                                    disableScrollLock: true,
                                                                                                }}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >
                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                    <em className='select_text'>{t('furnishingType')}</em>
                                                                                                </MenuItem>
                                                                                                {propertyMasterData?.FurnishingTypes?.map((item) => (
                                                                                                    <MenuItem
                                                                                                        className='drop_menus'
                                                                                                        key={item?.Id}
                                                                                                        value={item?.Id}
                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                    >
                                                                                                        {item?.Name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>

                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-6">
                                                                                    <div>
                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                            <Select
                                                                                                className='form-control border_temp'
                                                                                                sx={{ borderColor: 'transparent' }}
                                                                                                title={t('TenureProperty')}
                                                                                                displayEmpty
                                                                                                value={tenureOfTheProperty}
                                                                                                onChange={(e) => { setTenureOfTheProperty(e.target.value); }}
                                                                                                input={<OutlinedInput />}

                                                                                                MenuProps={{
                                                                                                    PaperProps: {
                                                                                                        style: {
                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                            width: 250,
                                                                                                        },
                                                                                                    },
                                                                                                    disableScrollLock: true,
                                                                                                }}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >
                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                    <em className='select_text'>{t('TenureProperty')}</em>
                                                                                                </MenuItem>
                                                                                                {propertyMasterData?.Tenures?.map((item) => (
                                                                                                    <MenuItem
                                                                                                        className='drop_menus'
                                                                                                        key={item?.Id}
                                                                                                        value={item?.Id}
                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                    >
                                                                                                        {item?.Name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>

                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="row clearfix">
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" class="form-control right_space" value={totalPlotSize} maxLength={10} onKeyPress={handleKeyPress} placeholder={t('totalPlotsize')} title={t('totalPlotsize')} onChange={(e) => setTotalPlotSize(e.target.value)} />
                                                                                            <span class="form_control_title">sqm</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-6">
                                                                                    <div class="form-group">
                                                                                        <div class="form-line">
                                                                                            <input type="text" class="form-control" placeholder={t('views')} title={t('views')} value={views} onChange={(e) => { setViews(e.target.value); }} />

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="row clearfix">

                                                                                <div class="col-sm-6">
                                                                                    <div>
                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                            <Select
                                                                                                className='form-control border_temp'
                                                                                                sx={{ borderColor: 'transparent' }}

                                                                                                displayEmpty
                                                                                                value={occupancy}
                                                                                                onChange={(e) => { setOccupancy(e.target.value); }}
                                                                                                input={<OutlinedInput />}
                                                                                                title={t('occupancy')}
                                                                                                MenuProps={{
                                                                                                    PaperProps: {
                                                                                                        style: {
                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                            width: 250,
                                                                                                        },
                                                                                                    },
                                                                                                    disableScrollLock: true,
                                                                                                }}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >
                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                    <em className='select_text'>{t('occupancy')}</em>
                                                                                                </MenuItem>
                                                                                                {propertyMasterData?.OccupancyTypes?.map((item) => (
                                                                                                    <MenuItem
                                                                                                        className='drop_menus'
                                                                                                        key={item?.Id}
                                                                                                        value={item?.Id}
                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                    >
                                                                                                        {item?.Name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>

                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-sm-6">
                                                                                    <div>
                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                            <Select
                                                                                                className='form-control border_temp'
                                                                                                sx={{ borderColor: 'transparent' }}

                                                                                                displayEmpty
                                                                                                value={renovationType}
                                                                                                onChange={(e) => { setRenovationType(e.target.value); }}
                                                                                                input={<OutlinedInput />}
                                                                                                title={t('renovationType')}
                                                                                                MenuProps={{
                                                                                                    PaperProps: {
                                                                                                        style: {
                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                            width: 250,
                                                                                                        },
                                                                                                    },
                                                                                                    disableScrollLock: true,
                                                                                                }}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >
                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                    <em className='select_text'>{t('renovationType')}</em>
                                                                                                </MenuItem>
                                                                                                {propertyMasterData?.RenovationTypes?.map((item) => (
                                                                                                    <MenuItem
                                                                                                        className='drop_menus'
                                                                                                        key={item?.Id}
                                                                                                        value={item?.Id}
                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                    >
                                                                                                        {item?.Name}
                                                                                                    </MenuItem>
                                                                                                ))}
                                                                                            </Select>

                                                                                        </FormControl>
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>) :
                                                                (residentCategory == 2 && rentalCategory == 1 && propertyTypesForBedroomsDisplay.includes(propertyType)) ? (<>
                                                                    <div class="panel-body">
                                                                        <div class="card">
                                                                            <div class="form_block">
                                                                                <div class="row clearfix">
                                                                                    <div class="col-sm-6">
                                                                                        <div>
                                                                                            <FormControl className='form-group' sx={{}}>
                                                                                                <Select
                                                                                                    className='form-control border_temp'
                                                                                                    sx={{ borderColor: 'transparent' }}
                                                                                                    ref={field1Ref}
                                                                                                    id="field1"
                                                                                                    displayEmpty
                                                                                                    value={propertyType}
                                                                                                    title={t('propertyType')}
                                                                                                    onChange={(e) => { setPropertyType(e.target.value); validatePropertyType(e.target.value); setNoOfBedrooms("") }}
                                                                                                    input={<OutlinedInput />}

                                                                                                    MenuProps={{
                                                                                                        PaperProps: {
                                                                                                            style: {
                                                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                width: 250,
                                                                                                            },
                                                                                                        },
                                                                                                        disableScrollLock: true,
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem className='drop_menus' value="">
                                                                                                        <em className='select_text'>{t('propertyType') + '*'}</em>
                                                                                                    </MenuItem>
                                                                                                    {selectedPropertyTypeDropdown?.map((item) => (
                                                                                                        <MenuItem
                                                                                                            className='drop_menus'
                                                                                                            key={item?.Id}
                                                                                                            value={item?.Id}
                                                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                                                        >
                                                                                                            {item?.Name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>
                                                                                                {
                                                                                                    propertyTypeError && (
                                                                                                        <span class="validation_notes">{propertyTypeError}</span>

                                                                                                    )
                                                                                                }
                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <div class="form-group">
                                                                                            <div class="form-line">
                                                                                                <input type="text" ref={field2Ref}
                                                                                                    id="field2" class="form-control right_space" placeholder={t('size')} value={size} onKeyPress={handleKeyPress} maxLength={6} title="Size" onChange={(e) => { setSize(e.target.value); validateSize(e.target.value) }} />
                                                                                                <span class="form_control_title">sqm</span>
                                                                                                {
                                                                                                    sizeError && (
                                                                                                        <span class="validation_notes">{sizeError}</span>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row clearfix">
                                                                                    <div class="col-sm-6">
                                                                                        <div>
                                                                                            <FormControl className='form-group' sx={{}}>
                                                                                                <Select
                                                                                                    className='form-control border_temp'
                                                                                                    sx={{ borderColor: 'transparent' }}

                                                                                                    displayEmpty
                                                                                                    value={noOfBedrooms}
                                                                                                    title={t('noBedrooms')}
                                                                                                    onChange={(e) => { setNoOfBedrooms(e.target.value); }}
                                                                                                    input={<OutlinedInput />}
                                                                                                    MenuProps={{
                                                                                                        PaperProps: {
                                                                                                            style: {
                                                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                width: 250,
                                                                                                            },
                                                                                                        },
                                                                                                        disableScrollLock: true,
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem className='drop_menus' value="">
                                                                                                        <em className='select_text'>{t('noBedrooms')}</em>
                                                                                                    </MenuItem>
                                                                                                    {numbersBedroom?.map((item) => (
                                                                                                        <MenuItem
                                                                                                            className='drop_menus'
                                                                                                            key={item?.Id}
                                                                                                            value={item?.Id}
                                                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                                                        >
                                                                                                            {item?.Name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>

                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="col-sm-6">
                                                                                        <div>
                                                                                            <FormControl className='form-group' sx={{}}>
                                                                                                <Select
                                                                                                    className='form-control border_temp'
                                                                                                    sx={{ borderColor: 'transparent' }}

                                                                                                    displayEmpty
                                                                                                    title={t('noBathrooms')}
                                                                                                    value={noOfBathrooms}
                                                                                                    onChange={(e) => { setNoOfBathrooms(e.target.value); }}
                                                                                                    input={<OutlinedInput />}
                                                                                                    MenuProps={{
                                                                                                        PaperProps: {
                                                                                                            style: {
                                                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                width: 250,
                                                                                                            },
                                                                                                        },
                                                                                                        disableScrollLock: true,
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem className='drop_menus' value="">
                                                                                                        <em className='select_text'>{t('noBathrooms')}</em>
                                                                                                    </MenuItem>
                                                                                                    {numbers?.map((item) => (
                                                                                                        <MenuItem
                                                                                                            className='drop_menus'
                                                                                                            key={item?.Id}
                                                                                                            value={item?.Id}
                                                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                                                        >
                                                                                                            {item?.Name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>

                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="row clearfix">
                                                                                    <div class="col-sm-6">
                                                                                        <div>
                                                                                            <FormControl className='form-group' sx={{}}>
                                                                                                <Select
                                                                                                    className='form-control border_temp'
                                                                                                    sx={{ borderColor: 'transparent' }}

                                                                                                    displayEmpty
                                                                                                    title={t('furnishingType')}
                                                                                                    value={furnishingType}
                                                                                                    onChange={(e) => { setFurnishingType(e.target.value); }}
                                                                                                    input={<OutlinedInput />}

                                                                                                    MenuProps={{
                                                                                                        PaperProps: {
                                                                                                            style: {
                                                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                width: 250,
                                                                                                            },
                                                                                                        },
                                                                                                        disableScrollLock: true,
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem className='drop_menus' value="">
                                                                                                        <em className='select_text'>{t('furnishingType')}</em>
                                                                                                    </MenuItem>
                                                                                                    {propertyMasterData?.FurnishingTypes?.map((item) => (
                                                                                                        <MenuItem
                                                                                                            className='drop_menus'
                                                                                                            key={item?.Id}
                                                                                                            value={item?.Id}
                                                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                                                        >
                                                                                                            {item?.Name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>

                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <div>
                                                                                            <FormControl className='form-group' sx={{}}>
                                                                                                <Select
                                                                                                    className='form-control border_temp'
                                                                                                    sx={{ borderColor: 'transparent' }}
                                                                                                    title={t('TenureProperty')}
                                                                                                    displayEmpty
                                                                                                    value={tenureOfTheProperty}
                                                                                                    onChange={(e) => { setTenureOfTheProperty(e.target.value); }}
                                                                                                    input={<OutlinedInput />}

                                                                                                    MenuProps={{
                                                                                                        PaperProps: {
                                                                                                            style: {
                                                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                width: 250,
                                                                                                            },
                                                                                                        },
                                                                                                        disableScrollLock: true,
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem className='drop_menus' value="">
                                                                                                        <em className='select_text'>{t('TenureProperty')}</em>
                                                                                                    </MenuItem>
                                                                                                    {propertyMasterData?.Tenures?.map((item) => (
                                                                                                        <MenuItem
                                                                                                            className='drop_menus'
                                                                                                            key={item?.Id}
                                                                                                            value={item?.Id}
                                                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                                                        >
                                                                                                            {item?.Name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>

                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="row clearfix">
                                                                                    <div class="col-sm-6">
                                                                                        <div class="form-group">
                                                                                            <div class="form-line">
                                                                                                <input type="text" class="form-control right_space" value={totalPlotSize} maxLength={10} onKeyPress={handleKeyPress} placeholder={t('totalPlotsize')} title={t('totalPlotsize')} onChange={(e) => setTotalPlotSize(e.target.value)} />
                                                                                                <span class="form_control_title">sqm</span>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <div class="form-group">
                                                                                            <div class="form-line">
                                                                                                <input type="text" class="form-control" placeholder={t('views')} title={t('views')} value={views} onChange={(e) => { setViews(e.target.value); }} />

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="row clearfix">

                                                                                    <div class="col-sm-6">
                                                                                        <div>
                                                                                            <FormControl className='form-group' sx={{}}>
                                                                                                <Select
                                                                                                    className='form-control border_temp'
                                                                                                    sx={{ borderColor: 'transparent' }}

                                                                                                    displayEmpty
                                                                                                    value={occupancy}
                                                                                                    onChange={(e) => { setOccupancy(e.target.value); }}
                                                                                                    input={<OutlinedInput />}
                                                                                                    title={t('occupancy')}
                                                                                                    MenuProps={{
                                                                                                        PaperProps: {
                                                                                                            style: {
                                                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                width: 250,
                                                                                                            },
                                                                                                        },
                                                                                                        disableScrollLock: true,
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem className='drop_menus' value="">
                                                                                                        <em className='select_text'>{t('occupancy')}</em>
                                                                                                    </MenuItem>
                                                                                                    {propertyMasterData?.OccupancyTypes?.map((item) => (
                                                                                                        <MenuItem
                                                                                                            className='drop_menus'
                                                                                                            key={item?.Id}
                                                                                                            value={item?.Id}
                                                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                                                        >
                                                                                                            {item?.Name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>

                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <div>
                                                                                            <FormControl className='form-group' sx={{}}>
                                                                                                <Select
                                                                                                    className='form-control border_temp'
                                                                                                    sx={{ borderColor: 'transparent' }}

                                                                                                    displayEmpty
                                                                                                    value={renovationType}
                                                                                                    onChange={(e) => { setRenovationType(e.target.value); }}
                                                                                                    input={<OutlinedInput />}
                                                                                                    title={t('renovationType')}
                                                                                                    MenuProps={{
                                                                                                        PaperProps: {
                                                                                                            style: {
                                                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                width: 250,
                                                                                                            },
                                                                                                        },
                                                                                                        disableScrollLock: true,
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem className='drop_menus' value="">
                                                                                                        <em className='select_text'>{t('renovationType')}</em>
                                                                                                    </MenuItem>
                                                                                                    {propertyMasterData?.RenovationTypes?.map((item) => (
                                                                                                        <MenuItem
                                                                                                            className='drop_menus'
                                                                                                            key={item?.Id}
                                                                                                            value={item?.Id}
                                                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                                                        >
                                                                                                            {item?.Name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>

                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row clearfix">


                                                                                    <div class="col-sm-6">
                                                                                        <div>
                                                                                            <FormControl className='form-group' sx={{}}>
                                                                                                <Select
                                                                                                    className='form-control border_temp'
                                                                                                    sx={{ borderColor: 'transparent' }}
                                                                                                    title={t('waterElectricity')}
                                                                                                    displayEmpty
                                                                                                    value={waterAndElectricity}
                                                                                                    onChange={(e) => { setWaterAndElectricity(e.target.value); }}
                                                                                                    input={<OutlinedInput />}

                                                                                                    MenuProps={{
                                                                                                        PaperProps: {
                                                                                                            style: {
                                                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                width: 250,
                                                                                                            },
                                                                                                        },
                                                                                                        disableScrollLock: true,
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem className='drop_menus' value="">
                                                                                                        <em className='select_text'>{t('waterElectricity')}</em>
                                                                                                    </MenuItem>
                                                                                                    {propertyMasterData?.WaterAndElectricities?.map((item) => (

                                                                                                        <MenuItem
                                                                                                            className='drop_menus'
                                                                                                            key={item?.Id}
                                                                                                            value={item?.Id}
                                                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                                                        >
                                                                                                            {item?.Name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>

                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>


                                                                                    <div class="col-sm-6">
                                                                                        <div class="form-group">
                                                                                            <div class="form-line">
                                                                                                <input type="text" title={'No. of Parking Space'} class="form-control right_space" maxLength={3} placeholder="No. of Parking Space" value={noOfParkingSpace} onKeyPress={handleKeyPress} onChange={(e) => { setNoOfParkingSpace(e.target.value) }} />
                                                                                                <span class="form_control_title">{t('parkings')}</span>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div></>) :
                                                                    (residentCategory == 2 && rentalCategory == 1) ? (<>
                                                                        <div class="panel-body">
                                                                            <div class="card">
                                                                                <div class="form_block">
                                                                                    <div class="row clearfix">
                                                                                        <div class="col-sm-6">
                                                                                            <div>
                                                                                                <FormControl className='form-group' sx={{}}>
                                                                                                    <Select
                                                                                                        className='form-control border_temp'
                                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                                        ref={field1Ref}
                                                                                                        id="field1"
                                                                                                        displayEmpty
                                                                                                        value={propertyType}
                                                                                                        title={t('propertyType')}
                                                                                                        onChange={(e) => { setPropertyType(e.target.value); validatePropertyType(e.target.value); setNoOfBedrooms("") }}
                                                                                                        input={<OutlinedInput />}

                                                                                                        MenuProps={{
                                                                                                            PaperProps: {
                                                                                                                style: {
                                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                    width: 250,
                                                                                                                },
                                                                                                            },
                                                                                                            disableScrollLock: true,
                                                                                                        }}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >
                                                                                                        <MenuItem className='drop_menus' value="">
                                                                                                            <em className='select_text'>{t('propertyType') + '*'}</em>
                                                                                                        </MenuItem>
                                                                                                        {selectedPropertyTypeDropdown?.map((item) => (
                                                                                                            <MenuItem
                                                                                                                className='drop_menus'
                                                                                                                key={item?.Id}
                                                                                                                value={item?.Id}
                                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                                            >
                                                                                                                {item?.Name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>
                                                                                                    {
                                                                                                        propertyTypeError && (
                                                                                                            <span class="validation_notes">{propertyTypeError}</span>

                                                                                                        )
                                                                                                    }
                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-sm-6">
                                                                                            <div class="form-group">
                                                                                                <div class="form-line">
                                                                                                    <input type="text" ref={field2Ref}
                                                                                                        id="field2" class="form-control right_space" placeholder={t('size')} value={size} onKeyPress={handleKeyPress} maxLength={6} title="Size" onChange={(e) => { setSize(e.target.value); validateSize(e.target.value) }} />
                                                                                                    <span class="form_control_title">sqm</span>
                                                                                                    {
                                                                                                        sizeError && (
                                                                                                            <span class="validation_notes">{sizeError}</span>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row clearfix">

                                                                                        <div class="col-sm-6">
                                                                                            <div>
                                                                                                <FormControl className='form-group' sx={{}}>
                                                                                                    <Select
                                                                                                        className='form-control border_temp'
                                                                                                        sx={{ borderColor: 'transparent' }}

                                                                                                        displayEmpty
                                                                                                        title={t('noBathrooms')}
                                                                                                        value={noOfBathrooms}
                                                                                                        onChange={(e) => { setNoOfBathrooms(e.target.value); }}
                                                                                                        input={<OutlinedInput />}
                                                                                                        MenuProps={{
                                                                                                            PaperProps: {
                                                                                                                style: {
                                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                    width: 250,
                                                                                                                },
                                                                                                            },
                                                                                                            disableScrollLock: true,
                                                                                                        }}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >
                                                                                                        <MenuItem className='drop_menus' value="">
                                                                                                            <em className='select_text'>{t('noBathrooms')}</em>
                                                                                                        </MenuItem>
                                                                                                        {numbers?.map((item) => (
                                                                                                            <MenuItem
                                                                                                                className='drop_menus'
                                                                                                                key={item?.Id}
                                                                                                                value={item?.Id}
                                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                                            >
                                                                                                                {item?.Name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>

                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-sm-6">
                                                                                            <div class="form-group">
                                                                                                <div class="form-line">
                                                                                                    <input type="text" title={'No. of Parking Space'} class="form-control right_space" maxLength={3} placeholder="No. of Parking Space" value={noOfParkingSpace} onKeyPress={handleKeyPress} onChange={(e) => { setNoOfParkingSpace(e.target.value) }} />
                                                                                                    <span class="form_control_title">{t('parkings')}</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div class="row clearfix">
                                                                                        <div class="col-sm-6">
                                                                                            <div>
                                                                                                <FormControl className='form-group' sx={{}}>
                                                                                                    <Select
                                                                                                        className='form-control border_temp'
                                                                                                        sx={{ borderColor: 'transparent' }}

                                                                                                        displayEmpty
                                                                                                        title={t('furnishingType')}
                                                                                                        value={furnishingType}
                                                                                                        onChange={(e) => { setFurnishingType(e.target.value); }}
                                                                                                        input={<OutlinedInput />}

                                                                                                        MenuProps={{
                                                                                                            PaperProps: {
                                                                                                                style: {
                                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                    width: 250,
                                                                                                                },
                                                                                                            },
                                                                                                            disableScrollLock: true,
                                                                                                        }}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >
                                                                                                        <MenuItem className='drop_menus' value="">
                                                                                                            <em className='select_text'>{t('furnishingType')}</em>
                                                                                                        </MenuItem>
                                                                                                        {propertyMasterData?.FurnishingTypes?.map((item) => (
                                                                                                            <MenuItem
                                                                                                                className='drop_menus'
                                                                                                                key={item?.Id}
                                                                                                                value={item?.Id}
                                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                                            >
                                                                                                                {item?.Name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>

                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-sm-6">
                                                                                            <div>
                                                                                                <FormControl className='form-group' sx={{}}>
                                                                                                    <Select
                                                                                                        className='form-control border_temp'
                                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                                        title={t('TenureProperty')}
                                                                                                        displayEmpty
                                                                                                        value={tenureOfTheProperty}
                                                                                                        onChange={(e) => { setTenureOfTheProperty(e.target.value); }}
                                                                                                        input={<OutlinedInput />}

                                                                                                        MenuProps={{
                                                                                                            PaperProps: {
                                                                                                                style: {
                                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                    width: 250,
                                                                                                                },
                                                                                                            },
                                                                                                            disableScrollLock: true,
                                                                                                        }}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >
                                                                                                        <MenuItem className='drop_menus' value="">
                                                                                                            <em className='select_text'>{t('TenureProperty')}</em>
                                                                                                        </MenuItem>
                                                                                                        {propertyMasterData?.Tenures?.map((item) => (
                                                                                                            <MenuItem
                                                                                                                className='drop_menus'
                                                                                                                key={item?.Id}
                                                                                                                value={item?.Id}
                                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                                            >
                                                                                                                {item?.Name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>

                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="row clearfix">
                                                                                        <div class="col-sm-6">
                                                                                            <div class="form-group">
                                                                                                <div class="form-line">
                                                                                                    <input type="text" class="form-control right_space" value={totalPlotSize} maxLength={10} onKeyPress={handleKeyPress} placeholder={t('totalPlotsize')} title={t('totalPlotsize')} onChange={(e) => setTotalPlotSize(e.target.value)} />
                                                                                                    <span class="form_control_title">sqm</span>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-sm-6">
                                                                                            <div class="form-group">
                                                                                                <div class="form-line">
                                                                                                    <input type="text" class="form-control" placeholder={t('views')} title={t('views')} value={views} onChange={(e) => { setViews(e.target.value); }} />

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div class="row clearfix">

                                                                                        <div class="col-sm-6">
                                                                                            <div>
                                                                                                <FormControl className='form-group' sx={{}}>
                                                                                                    <Select
                                                                                                        className='form-control border_temp'
                                                                                                        sx={{ borderColor: 'transparent' }}

                                                                                                        displayEmpty
                                                                                                        value={occupancy}
                                                                                                        onChange={(e) => { setOccupancy(e.target.value); }}
                                                                                                        input={<OutlinedInput />}
                                                                                                        title={t('occupancy')}
                                                                                                        MenuProps={{
                                                                                                            PaperProps: {
                                                                                                                style: {
                                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                    width: 250,
                                                                                                                },
                                                                                                            },
                                                                                                            disableScrollLock: true,
                                                                                                        }}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >
                                                                                                        <MenuItem className='drop_menus' value="">
                                                                                                            <em className='select_text'>{t('occupancy')}</em>
                                                                                                        </MenuItem>
                                                                                                        {propertyMasterData?.OccupancyTypes?.map((item) => (
                                                                                                            <MenuItem
                                                                                                                className='drop_menus'
                                                                                                                key={item?.Id}
                                                                                                                value={item?.Id}
                                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                                            >
                                                                                                                {item?.Name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>

                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-sm-6">
                                                                                            <div>
                                                                                                <FormControl className='form-group' sx={{}}>
                                                                                                    <Select
                                                                                                        className='form-control border_temp'
                                                                                                        sx={{ borderColor: 'transparent' }}

                                                                                                        displayEmpty
                                                                                                        value={renovationType}
                                                                                                        onChange={(e) => { setRenovationType(e.target.value); }}
                                                                                                        input={<OutlinedInput />}
                                                                                                        title={t('renovationType')}
                                                                                                        MenuProps={{
                                                                                                            PaperProps: {
                                                                                                                style: {
                                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                    width: 250,
                                                                                                                },
                                                                                                            },
                                                                                                            disableScrollLock: true,
                                                                                                        }}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >
                                                                                                        <MenuItem className='drop_menus' value="">
                                                                                                            <em className='select_text'>{t('renovationType')}</em>
                                                                                                        </MenuItem>
                                                                                                        {propertyMasterData?.RenovationTypes?.map((item) => (
                                                                                                            <MenuItem
                                                                                                                className='drop_menus'
                                                                                                                key={item?.Id}
                                                                                                                value={item?.Id}
                                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                                            >
                                                                                                                {item?.Name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>

                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row clearfix">


                                                                                        <div class="col-sm-6">
                                                                                            <div>
                                                                                                <FormControl className='form-group' sx={{}}>
                                                                                                    <Select
                                                                                                        className='form-control border_temp'
                                                                                                        sx={{ borderColor: 'transparent' }}
                                                                                                        title={t('waterElectricity')}
                                                                                                        displayEmpty
                                                                                                        value={waterAndElectricity}
                                                                                                        onChange={(e) => { setWaterAndElectricity(e.target.value); }}
                                                                                                        input={<OutlinedInput />}

                                                                                                        MenuProps={{
                                                                                                            PaperProps: {
                                                                                                                style: {
                                                                                                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                    width: 250,
                                                                                                                },
                                                                                                            },
                                                                                                            disableScrollLock: true,
                                                                                                        }}
                                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                                    >
                                                                                                        <MenuItem className='drop_menus' value="">
                                                                                                            <em className='select_text'>{t('waterElectricity')}</em>
                                                                                                        </MenuItem>
                                                                                                        {propertyMasterData?.WaterAndElectricities?.map((item) => (

                                                                                                            <MenuItem
                                                                                                                className='drop_menus'
                                                                                                                key={item?.Id}
                                                                                                                value={item?.Id}
                                                                                                                style={getStyles(item?.Name, personName, theme)}
                                                                                                            >
                                                                                                                {item?.Name}
                                                                                                            </MenuItem>
                                                                                                        ))}
                                                                                                    </Select>

                                                                                                </FormControl>
                                                                                            </div>
                                                                                        </div>



                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                        </div></>) :
                                                                        (residentCategory == 2 && rentalCategory == 2 && propertyTypesForBedroomsDisplay.includes(propertyType)) ? (<>
                                                                            <div class="panel-body">
                                                                                <div class="card">
                                                                                    <div class="form_block">
                                                                                        <div class="row clearfix">
                                                                                            <div class="col-sm-6">
                                                                                                <div>
                                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                                        <Select
                                                                                                            className='form-control border_temp'
                                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                                            ref={field1Ref}
                                                                                                            id="field1"
                                                                                                            displayEmpty
                                                                                                            value={propertyType}
                                                                                                            title={t('propertyType')}
                                                                                                            onChange={(e) => { setPropertyType(e.target.value); validatePropertyType(e.target.value); setNoOfBedrooms("") }}
                                                                                                            input={<OutlinedInput />}

                                                                                                            MenuProps={{
                                                                                                                PaperProps: {
                                                                                                                    style: {
                                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                        width: 250,
                                                                                                                    },
                                                                                                                },
                                                                                                                disableScrollLock: true,
                                                                                                            }}
                                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                                        >
                                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                                <em className='select_text'>{t('propertyType') + '*'}</em>
                                                                                                            </MenuItem>
                                                                                                            {selectedPropertyTypeDropdown?.map((item) => (
                                                                                                                <MenuItem
                                                                                                                    className='drop_menus'
                                                                                                                    key={item?.Id}
                                                                                                                    value={item?.Id}
                                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                                >
                                                                                                                    {item?.Name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>
                                                                                                        {
                                                                                                            propertyTypeError && (
                                                                                                                <span class="validation_notes">{propertyTypeError}</span>

                                                                                                            )
                                                                                                        }
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <div class="form-line">
                                                                                                        <input type="text" ref={field2Ref}
                                                                                                            id="field2" class="form-control right_space" placeholder={t('size')} value={size} onKeyPress={handleKeyPress} maxLength={6} title="Size" onChange={(e) => { setSize(e.target.value); validateSize(e.target.value) }} />
                                                                                                        <span class="form_control_title">sqm</span>
                                                                                                        {
                                                                                                            sizeError && (
                                                                                                                <span class="validation_notes">{sizeError}</span>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row clearfix">
                                                                                            <div class="col-sm-6">
                                                                                                <div>
                                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                                        <Select
                                                                                                            className='form-control border_temp'
                                                                                                            sx={{ borderColor: 'transparent' }}

                                                                                                            displayEmpty
                                                                                                            value={noOfBedrooms}
                                                                                                            title={t('noBedrooms')}
                                                                                                            onChange={(e) => { setNoOfBedrooms(e.target.value); }}
                                                                                                            input={<OutlinedInput />}
                                                                                                            MenuProps={{
                                                                                                                PaperProps: {
                                                                                                                    style: {
                                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                        width: 250,
                                                                                                                    },
                                                                                                                },
                                                                                                                disableScrollLock: true,
                                                                                                            }}
                                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                                        >
                                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                                <em className='select_text'>{t('noBedrooms')}</em>
                                                                                                            </MenuItem>
                                                                                                            {numbersBedroom?.map((item) => (
                                                                                                                <MenuItem
                                                                                                                    className='drop_menus'
                                                                                                                    key={item?.Id}
                                                                                                                    value={item?.Id}
                                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                                >
                                                                                                                    {item?.Name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>

                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-sm-6">
                                                                                                <div>
                                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                                        <Select
                                                                                                            className='form-control border_temp'
                                                                                                            sx={{ borderColor: 'transparent' }}

                                                                                                            displayEmpty
                                                                                                            title={t('noBathrooms')}
                                                                                                            value={noOfBathrooms}
                                                                                                            onChange={(e) => { setNoOfBathrooms(e.target.value); }}
                                                                                                            input={<OutlinedInput />}
                                                                                                            MenuProps={{
                                                                                                                PaperProps: {
                                                                                                                    style: {
                                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                        width: 250,
                                                                                                                    },
                                                                                                                },
                                                                                                                disableScrollLock: true,
                                                                                                            }}
                                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                                        >
                                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                                <em className='select_text'>{t('noBathrooms')}</em>
                                                                                                            </MenuItem>
                                                                                                            {numbers?.map((item) => (
                                                                                                                <MenuItem
                                                                                                                    className='drop_menus'
                                                                                                                    key={item?.Id}
                                                                                                                    value={item?.Id}
                                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                                >
                                                                                                                    {item?.Name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>

                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div class="row clearfix">
                                                                                            <div class="col-sm-6">
                                                                                                <div>
                                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                                        <Select
                                                                                                            className='form-control border_temp'
                                                                                                            sx={{ borderColor: 'transparent' }}

                                                                                                            displayEmpty
                                                                                                            title={t('furnishingType')}
                                                                                                            value={furnishingType}
                                                                                                            onChange={(e) => { setFurnishingType(e.target.value); }}
                                                                                                            input={<OutlinedInput />}

                                                                                                            MenuProps={{
                                                                                                                PaperProps: {
                                                                                                                    style: {
                                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                        width: 250,
                                                                                                                    },
                                                                                                                },
                                                                                                                disableScrollLock: true,
                                                                                                            }}
                                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                                        >
                                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                                <em className='select_text'>{t('furnishingType')}</em>
                                                                                                            </MenuItem>
                                                                                                            {propertyMasterData?.FurnishingTypes?.map((item) => (
                                                                                                                <MenuItem
                                                                                                                    className='drop_menus'
                                                                                                                    key={item?.Id}
                                                                                                                    value={item?.Id}
                                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                                >
                                                                                                                    {item?.Name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>

                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-sm-6">
                                                                                                <div>
                                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                                        <Select
                                                                                                            className='form-control border_temp'
                                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                                            title={t('TenureProperty')}
                                                                                                            displayEmpty
                                                                                                            value={tenureOfTheProperty}
                                                                                                            onChange={(e) => { setTenureOfTheProperty(e.target.value); }}
                                                                                                            input={<OutlinedInput />}

                                                                                                            MenuProps={{
                                                                                                                PaperProps: {
                                                                                                                    style: {
                                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                        width: 250,
                                                                                                                    },
                                                                                                                },
                                                                                                                disableScrollLock: true,
                                                                                                            }}
                                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                                        >
                                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                                <em className='select_text'>{t('TenureProperty')}</em>
                                                                                                            </MenuItem>
                                                                                                            {propertyMasterData?.Tenures?.map((item) => (
                                                                                                                <MenuItem
                                                                                                                    className='drop_menus'
                                                                                                                    key={item?.Id}
                                                                                                                    value={item?.Id}
                                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                                >
                                                                                                                    {item?.Name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>

                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row clearfix">
                                                                                            <div class="col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <div class="form-line">
                                                                                                        <input type="text" class="form-control right_space" value={totalPlotSize} maxLength={10} onKeyPress={handleKeyPress} placeholder={t('totalPlotsize')} title={t('totalPlotsize')} onChange={(e) => setTotalPlotSize(e.target.value)} />
                                                                                                        <span class="form_control_title">sqm</span>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <div class="form-line">
                                                                                                        <input type="text" class="form-control" placeholder={t('views')} title={t('views')} value={views} onChange={(e) => { setViews(e.target.value); }} />

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row clearfix">

                                                                                            <div class="col-sm-6">
                                                                                                <div>
                                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                                        <Select
                                                                                                            className='form-control border_temp'
                                                                                                            sx={{ borderColor: 'transparent' }}

                                                                                                            displayEmpty
                                                                                                            value={occupancy}
                                                                                                            onChange={(e) => { setOccupancy(e.target.value); }}
                                                                                                            input={<OutlinedInput />}
                                                                                                            title={t('occupancy')}
                                                                                                            MenuProps={{
                                                                                                                PaperProps: {
                                                                                                                    style: {
                                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                        width: 250,
                                                                                                                    },
                                                                                                                },
                                                                                                                disableScrollLock: true,
                                                                                                            }}
                                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                                        >
                                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                                <em className='select_text'>{t('occupancy')}</em>
                                                                                                            </MenuItem>
                                                                                                            {propertyMasterData?.OccupancyTypes?.map((item) => (
                                                                                                                <MenuItem
                                                                                                                    className='drop_menus'
                                                                                                                    key={item?.Id}
                                                                                                                    value={item?.Id}
                                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                                >
                                                                                                                    {item?.Name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>

                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-sm-6">
                                                                                                <div>
                                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                                        <Select
                                                                                                            className='form-control border_temp'
                                                                                                            sx={{ borderColor: 'transparent' }}

                                                                                                            displayEmpty
                                                                                                            value={renovationType}
                                                                                                            onChange={(e) => { setRenovationType(e.target.value); }}
                                                                                                            input={<OutlinedInput />}
                                                                                                            title={t('renovationType')}
                                                                                                            MenuProps={{
                                                                                                                PaperProps: {
                                                                                                                    style: {
                                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                        width: 250,
                                                                                                                    },
                                                                                                                },
                                                                                                                disableScrollLock: true,
                                                                                                            }}
                                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                                        >
                                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                                <em className='select_text'>{t('renovationType')}</em>
                                                                                                            </MenuItem>
                                                                                                            {propertyMasterData?.RenovationTypes?.map((item) => (
                                                                                                                <MenuItem
                                                                                                                    className='drop_menus'
                                                                                                                    key={item?.Id}
                                                                                                                    value={item?.Id}
                                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                                >
                                                                                                                    {item?.Name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>

                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row clearfix">




                                                                                            <div class="col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <div class="form-line">
                                                                                                        <input type="text" title={'No. of Parking Space'} class="form-control right_space" maxLength={3} placeholder="No. of Parking Space" value={noOfParkingSpace} onKeyPress={handleKeyPress} onChange={(e) => { setNoOfParkingSpace(e.target.value) }} />
                                                                                                        <span class="form_control_title">{t('parkings')}</span>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div></>) :
                                                                            (residentCategory == 2 && rentalCategory == 2) ? (<>

                                                                                <div class="panel-body">
                                                                                    <div class="card">
                                                                                        <div class="form_block">
                                                                                            <div class="row clearfix">
                                                                                                <div class="col-sm-6">
                                                                                                    <div>
                                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                                            <Select
                                                                                                                className='form-control border_temp'
                                                                                                                sx={{ borderColor: 'transparent' }}
                                                                                                                ref={field1Ref}
                                                                                                                id="field1"
                                                                                                                displayEmpty
                                                                                                                value={propertyType}
                                                                                                                title={t('propertyType')}
                                                                                                                onChange={(e) => { setPropertyType(e.target.value); validatePropertyType(e.target.value); setNoOfBedrooms("") }}
                                                                                                                input={<OutlinedInput />}

                                                                                                                MenuProps={{
                                                                                                                    PaperProps: {
                                                                                                                        style: {
                                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                            width: 250,
                                                                                                                        },
                                                                                                                    },
                                                                                                                    disableScrollLock: true,
                                                                                                                }}
                                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                                            >
                                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                                    <em className='select_text'>{t('propertyType') + '*'}</em>
                                                                                                                </MenuItem>
                                                                                                                {selectedPropertyTypeDropdown?.map((item) => (
                                                                                                                    <MenuItem
                                                                                                                        className='drop_menus'
                                                                                                                        key={item?.Id}
                                                                                                                        value={item?.Id}
                                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                                    >
                                                                                                                        {item?.Name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </Select>
                                                                                                            {
                                                                                                                propertyTypeError && (
                                                                                                                    <span class="validation_notes">{propertyTypeError}</span>

                                                                                                                )
                                                                                                            }
                                                                                                        </FormControl>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-6">
                                                                                                    <div class="form-group">
                                                                                                        <div class="form-line">
                                                                                                            <input type="text" ref={field2Ref}
                                                                                                                id="field2" class="form-control right_space" placeholder={t('size')} value={size} onKeyPress={handleKeyPress} maxLength={6} title="Size" onChange={(e) => { setSize(e.target.value); validateSize(e.target.value) }} />
                                                                                                            <span class="form_control_title">sqm</span>
                                                                                                            {
                                                                                                                sizeError && (
                                                                                                                    <span class="validation_notes">{sizeError}</span>
                                                                                                                )
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="row clearfix">


                                                                                                <div class="col-sm-6">
                                                                                                    <div>
                                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                                            <Select
                                                                                                                className='form-control border_temp'
                                                                                                                sx={{ borderColor: 'transparent' }}

                                                                                                                displayEmpty
                                                                                                                title={t('noBathrooms')}
                                                                                                                value={noOfBathrooms}
                                                                                                                onChange={(e) => { setNoOfBathrooms(e.target.value); }}
                                                                                                                input={<OutlinedInput />}
                                                                                                                MenuProps={{
                                                                                                                    PaperProps: {
                                                                                                                        style: {
                                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                            width: 250,
                                                                                                                        },
                                                                                                                    },
                                                                                                                    disableScrollLock: true,
                                                                                                                }}
                                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                                            >
                                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                                    <em className='select_text'>{t('noBathrooms')}</em>
                                                                                                                </MenuItem>
                                                                                                                {numbers?.map((item) => (
                                                                                                                    <MenuItem
                                                                                                                        className='drop_menus'
                                                                                                                        key={item?.Id}
                                                                                                                        value={item?.Id}
                                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                                    >
                                                                                                                        {item?.Name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </Select>

                                                                                                        </FormControl>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-6">
                                                                                                    <div class="form-group">
                                                                                                        <div class="form-line">
                                                                                                            <input type="text" title={'No. of Parking Space'} class="form-control right_space" maxLength={3} placeholder="No. of Parking Space" value={noOfParkingSpace} onKeyPress={handleKeyPress} onChange={(e) => { setNoOfParkingSpace(e.target.value) }} />
                                                                                                            <span class="form_control_title">{t('parkings')}</span>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div class="row clearfix">
                                                                                                <div class="col-sm-6">
                                                                                                    <div>
                                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                                            <Select
                                                                                                                className='form-control border_temp'
                                                                                                                sx={{ borderColor: 'transparent' }}

                                                                                                                displayEmpty
                                                                                                                title={t('furnishingType')}
                                                                                                                value={furnishingType}
                                                                                                                onChange={(e) => { setFurnishingType(e.target.value); }}
                                                                                                                input={<OutlinedInput />}

                                                                                                                MenuProps={{
                                                                                                                    PaperProps: {
                                                                                                                        style: {
                                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                            width: 250,
                                                                                                                        },
                                                                                                                    },
                                                                                                                    disableScrollLock: true,
                                                                                                                }}
                                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                                            >
                                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                                    <em className='select_text'>{t('furnishingType')}</em>
                                                                                                                </MenuItem>
                                                                                                                {propertyMasterData?.FurnishingTypes?.map((item) => (
                                                                                                                    <MenuItem
                                                                                                                        className='drop_menus'
                                                                                                                        key={item?.Id}
                                                                                                                        value={item?.Id}
                                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                                    >
                                                                                                                        {item?.Name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </Select>

                                                                                                        </FormControl>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-6">
                                                                                                    <div>
                                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                                            <Select
                                                                                                                className='form-control border_temp'
                                                                                                                sx={{ borderColor: 'transparent' }}
                                                                                                                title={t('TenureProperty')}
                                                                                                                displayEmpty
                                                                                                                value={tenureOfTheProperty}
                                                                                                                onChange={(e) => { setTenureOfTheProperty(e.target.value); }}
                                                                                                                input={<OutlinedInput />}

                                                                                                                MenuProps={{
                                                                                                                    PaperProps: {
                                                                                                                        style: {
                                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                            width: 250,
                                                                                                                        },
                                                                                                                    },
                                                                                                                    disableScrollLock: true,
                                                                                                                }}
                                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                                            >
                                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                                    <em className='select_text'>{t('TenureProperty')}</em>
                                                                                                                </MenuItem>
                                                                                                                {propertyMasterData?.Tenures?.map((item) => (
                                                                                                                    <MenuItem
                                                                                                                        className='drop_menus'
                                                                                                                        key={item?.Id}
                                                                                                                        value={item?.Id}
                                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                                    >
                                                                                                                        {item?.Name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </Select>

                                                                                                        </FormControl>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="row clearfix">
                                                                                                <div class="col-sm-6">
                                                                                                    <div class="form-group">
                                                                                                        <div class="form-line">
                                                                                                            <input type="text" class="form-control right_space" value={totalPlotSize} maxLength={10} onKeyPress={handleKeyPress} placeholder={t('totalPlotsize')} title={t('totalPlotsize')} onChange={(e) => setTotalPlotSize(e.target.value)} />
                                                                                                            <span class="form_control_title">sqm</span>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-6">
                                                                                                    <div class="form-group">
                                                                                                        <div class="form-line">
                                                                                                            <input type="text" class="form-control" placeholder={t('views')} title={t('views')} value={views} onChange={(e) => { setViews(e.target.value); }} />

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="row clearfix">

                                                                                                <div class="col-sm-6">
                                                                                                    <div>
                                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                                            <Select
                                                                                                                className='form-control border_temp'
                                                                                                                sx={{ borderColor: 'transparent' }}

                                                                                                                displayEmpty
                                                                                                                value={occupancy}
                                                                                                                onChange={(e) => { setOccupancy(e.target.value); }}
                                                                                                                input={<OutlinedInput />}
                                                                                                                title={t('occupancy')}
                                                                                                                MenuProps={{
                                                                                                                    PaperProps: {
                                                                                                                        style: {
                                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                            width: 250,
                                                                                                                        },
                                                                                                                    },
                                                                                                                    disableScrollLock: true,
                                                                                                                }}
                                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                                            >
                                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                                    <em className='select_text'>{t('occupancy')}</em>
                                                                                                                </MenuItem>
                                                                                                                {propertyMasterData?.OccupancyTypes?.map((item) => (
                                                                                                                    <MenuItem
                                                                                                                        className='drop_menus'
                                                                                                                        key={item?.Id}
                                                                                                                        value={item?.Id}
                                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                                    >
                                                                                                                        {item?.Name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </Select>

                                                                                                        </FormControl>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="col-sm-6">
                                                                                                    <div>
                                                                                                        <FormControl className='form-group' sx={{}}>
                                                                                                            <Select
                                                                                                                className='form-control border_temp'
                                                                                                                sx={{ borderColor: 'transparent' }}

                                                                                                                displayEmpty
                                                                                                                value={renovationType}
                                                                                                                onChange={(e) => { setRenovationType(e.target.value); }}
                                                                                                                input={<OutlinedInput />}
                                                                                                                title={t('renovationType')}
                                                                                                                MenuProps={{
                                                                                                                    PaperProps: {
                                                                                                                        style: {
                                                                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                                            width: 250,
                                                                                                                        },
                                                                                                                    },
                                                                                                                    disableScrollLock: true,
                                                                                                                }}
                                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                                            >
                                                                                                                <MenuItem className='drop_menus' value="">
                                                                                                                    <em className='select_text'>{t('renovationType')}</em>
                                                                                                                </MenuItem>
                                                                                                                {propertyMasterData?.RenovationTypes?.map((item) => (
                                                                                                                    <MenuItem
                                                                                                                        className='drop_menus'
                                                                                                                        key={item?.Id}
                                                                                                                        value={item?.Id}
                                                                                                                        style={getStyles(item?.Name, personName, theme)}
                                                                                                                    >
                                                                                                                        {item?.Name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </Select>

                                                                                                        </FormControl>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>



                                                                                        </div>
                                                                                    </div>
                                                                                </div></>) :
                                                                                (<></>)}
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_1" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_1">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openClosePropertyAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_1">
                                                                <span>{t('Location')}</span>
                                                                <i className={propertyAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {propertyAccordingList ?
                                                        <>
                                                            <div id="collapseOne_1" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_1">
                                                                <div class="panel-body">
                                                                    <div class="card">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form_block">

                                                                                    <div className='form-group' id="field8" ref={field8Ref}>
                                                                                        <FormControl className='auto_complete_form' sx={{}}>
                                                                                            <Autocomplete
                                                                                                disablePortal
                                                                                                id="combo-box-demo"
                                                                                                sx={{ width: 300, fontSize: 14, }}
                                                                                                className='form-control auto_complete'
                                                                                                title='City'
                                                                                                disabled={!propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? true : false}
                                                                                                onChange={handleCitySelect}
                                                                                                options={cityDataSeachOption}
                                                                                                getOptionLabel={(option) => option?.Name} // Adjust this based on your API response structure
                                                                                                onInputChange={(event, newInputValue) => { handleCitySearch(newInputValue); }}
                                                                                                renderInput={(params) => <TextField {...params} placeholder={prAddressSelected == null || prAddressSelected == '' ? "City*" : prAddressSelected}

                                                                                                />}
                                                                                            />
                                                                                        </FormControl>

                                                                                        {
                                                                                            cityAddressError && (
                                                                                                <span class="validation_notes">{cityAddressError}</span>

                                                                                            )
                                                                                        }
                                                                                    </div>



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                {
                                                                                    locationCoordinates?.lat == null || locationCoordinates?.lat == "" ?
                                                                                        <iframe src={`https://maps.google.com/maps?q=25.9434256,50.6014985&hl=es;&output=embed`} width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                                        :
                                                                                        <iframe src={`https://maps.google.com/maps?q=${locationCoordinates?.lat},${locationCoordinates?.lng}&hl=es;&output=embed`} width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_5" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_5">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openClosePricingAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_5">
                                                                <span>{t('pricing')}</span>
                                                                <i className={pricingAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {pricingAccordingList ?
                                                        <div id="collapseOne_5" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_5">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" disabled={!propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null ? true : false} class="form-control right_space" title={rentalCategory == 1 ? "Monthly Price" : "Price"} placeholder={rentalCategory == 1 ? "Monthly Price*" : "Price*"} value={monthlyPrice} maxLength={7}
                                                                                            ref={field3Ref}
                                                                                            id="field3" onKeyPress={handleKeyPress} onChange={(e) => { setMonthlyPrice(e.target.value); validateMonthlyPrice(e.target.value) }} />
                                                                                        <span class="form_control_title">{t('BHD')}</span>

                                                                                        {
                                                                                            monthlyPriceError && (
                                                                                                <span class="validation_notes">{monthlyPriceError}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div class="row clearfix">



                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_6" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_6">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseDescriptionAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_6">
                                                                <span>Description</span>
                                                                <i className={descriptionAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {descriptionAccordingList ?
                                                        <div id="collapseOne_6" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_6">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="form_block">
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-6">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <input type="text" ref={field4Ref}
                                                                                            id="field4" class="form-control" maxLength={50} value={title} title={t('title')} placeholder={t('title') + '*'} onChange={(e) => { setTitle(e.target.value); validateTitle(e.target.value) }} />
                                                                                        <span class="counter_text" >{title?.length == 0 ? 0 : title?.trim()?.length}</span>
                                                                                        {
                                                                                            titleError &&
                                                                                            (
                                                                                                <span class="validation_notes">{titleError}</span>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <div>
                                                                                    <FormControl className='form-group' sx={{}}>
                                                                                        <Select
                                                                                            className='form-control border_temp'
                                                                                            sx={{ borderColor: 'transparent' }}
                                                                                            ref={field5Ref}
                                                                                            title={t('language')}
                                                                                            id="field5"
                                                                                            displayEmpty
                                                                                            value={language}
                                                                                            onChange={(e) => { setLanguage(e.target.value); validateLanguage(e.target.value); setDescription('') }}
                                                                                            input={<OutlinedInput />}

                                                                                            MenuProps={{
                                                                                                PaperProps: {
                                                                                                    style: {
                                                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                                        width: 250,
                                                                                                    },
                                                                                                },
                                                                                                disableScrollLock: true,
                                                                                            }}
                                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                                        >
                                                                                            <MenuItem className='drop_menus' value="">
                                                                                                <em className='select_text'>{t('language') + '*'}</em>
                                                                                            </MenuItem>
                                                                                            {propertyMasterData?.Languages?.map((item) => (
                                                                                                <MenuItem
                                                                                                    className='drop_menus'
                                                                                                    key={item?.Id}
                                                                                                    value={item?.Id}
                                                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                                                >
                                                                                                    {item?.Name}
                                                                                                </MenuItem>
                                                                                            ))}
                                                                                        </Select>
                                                                                        {
                                                                                            languageError && (
                                                                                                <span class="validation_notes">{languageError}</span>
                                                                                            )
                                                                                        }
                                                                                    </FormControl>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row clearfix">
                                                                            <div class="col-sm-12">
                                                                                <div class="form-group">
                                                                                    <div class="form-line">
                                                                                        <textarea
                                                                                            rows="4"
                                                                                            ref={field6Ref}
                                                                                            title={t('description')}
                                                                                            style={{
                                                                                                direction: language === 2 ? 'rtl' : 'ltr',
                                                                                                textAlign: language === 2 ? 'right' : 'left'
                                                                                            }}
                                                                                            id="field6"
                                                                                            maxLength={1600}
                                                                                            className="form-control no-resize"
                                                                                            value={description}
                                                                                            placeholder={language === 2 ? '*' + t('description') : t('description') + '*'}
                                                                                            onChange={(e) => { setDescription(e.target.value); validateDescription(e.target.value) }}
                                                                                            dir={language === 2 ? 'ltr' : 'auto'} // Set the direction of the input text to LTR
                                                                                        ></textarea>
                                                                                        <span className="counter_text">{description?.length === 0 ? 0 : description?.trim()?.length}</span>
                                                                                        {descriptionError && (
                                                                                            <span className="validation_notes">{descriptionError}</span>
                                                                                        )}
                                                                                        {language === 2 && (
                                                                                            <style>
                                                                                                {`#field6::placeholder {text-align: left;direction: ltr;}`}
                                                                                            </style>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                            <div class="panel-group full-body" id="accordion_7" role="tablist" aria-multiselectable="true">
                                                <div class="panel">
                                                    <div class="panel-heading" role="tab" id="headingOne_7">
                                                        <h4 class="panel-title">
                                                            <a href="javascript:void(0)" role="button" onClick={() => openCloseAmenitiesAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_7">
                                                                <span>{t('selectAmenities')}</span>
                                                                <i className={amenitiesAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    {amenitiesAccordingList ?
                                                        <>
                                                            <div id="collapseOne_7" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_7">
                                                                <div class="panel-body">
                                                                    <div class="card">
                                                                        <div class="amenities_block">
                                                                            <p>{t('amenitiesDisplayed')}</p>
                                                                            <div class="amenities_list">


                                                                                {
                                                                                    selectAminitiesSelected?.map((item) => {
                                                                                        return (
                                                                                            <span>{item?.Name}</span>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <a onClick={handleOpen}>+ {t('addAmenities')}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="button_block">
                                                                <a href="#" class="links" id="fieldDefault" ref={fieldDefaultRef} onClick={() => propertyDetailsApi()}>{t('Save')}</a>
                                                                <a href="#" class="links" onClick={() => nextTab()}>{t('next')}</a>
                                                            </div>
                                                        </> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="rightside_block">
                                                <h4>{t('draftProperty')}</h4>
                                                <div class="update_block">
                                                    <p><span>{t('lastUpdate')}: {modifiedDateStr}</span> </p>
                                                    <p><span>{'Created'}: {createdDateStr}</span> </p>
                                                    <p><span>{t('createdBy')}:</span> {createdByName}</p>
                                                </div>
                                                <div class="listing_status_block">
                                                    <h5>{t('listingStatus')}</h5>
                                                    {
                                                        !propertyMode?.mode == 0 && (
                                                            <div class="listing_block">
                                                                {
                                                                    getPropertyData?.IsApplied ?
                                                                        <h6>Requested to Publish</h6>
                                                                        :
                                                                        <>
                                                                            {
                                                                                getPropertyData?.SubscriptionPlanId != null && (
                                                                                    <p>{getPropertyData?.SubscriptionPlanId == 1 ? "Standard" : getPropertyData?.SubscriptionPlanId == 2 ? "Featured" : getPropertyData?.SubscriptionPlanId == 3 ? "Featured" : getPropertyData?.SubscriptionPlanId == 4 ? "Premium" : "Premium"} Listing</p>

                                                                                )
                                                                            }
                                                                            {
                                                                                getPropertyData?.SubscriptionPlanId != 4 && getPropertyData?.SubscriptionPlanId != 5 && (
                                                                                    <a href='#' onClick={() => getPropertyData?.SubscriptionPlanId == null ? handlePublishOpen(getPropertyData?.SubscriptionPlanId) : handleUpgradePlanOpen(getPropertyData?.SubscriptionPlanId)} class={getPropertyData?.SubscriptionPlanId == null ? "status green" : "status blue"}>{getPropertyData?.SubscriptionPlanId == null ? "Publish" : "Upgrade"}</a>

                                                                                )
                                                                            }</>
                                                                }

                                                            </div>
                                                        )
                                                    }




                                                    {
                                                        (!propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null && getPropertyData?.IsAutoRenew == false) ?
                                                            <p>Expire Date : {getPropertyData?.BoosterEndDateStr}</p>
                                                            :
                                                            null

                                                    }

                                                    {
                                                        (!propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != null && getPropertyData?.IsAutoRenew == true) ?
                                                            <p>Auto renews on : {getPropertyData?.BoosterEndDateStr}</p>
                                                            :
                                                            null

                                                    }
                                                    {
                                                        ((!propertyMode?.mode == 0 && getPropertyData?.SubscriptionPlanId != 1 && getPropertyData?.SubscriptionPlanId != null)) ?
                                                            <div class="table_heading">
                                                                <h5 style={{ display: 'inline-block' }}>Auto-renew</h5>
                                                                <div style={{ display: 'inline-block' }} >
                                                                    <Switch {...label}
                                                                        className='switch_bar'
                                                                        checked={autoRenew}
                                                                        onChange={() => handleChangeAutoRenew()}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            null

                                                    }


                                                </div>
                                                <div class="quality_score_block">
                                                    <div class="quality_score_title">
                                                        <h5>{t('qualityScore')}</h5>

                                                        <div class="quality_block">

                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: qualityScore?.QualityGrade == 'Emerald(E)' ? '#FDB51F' : '#193057' }} class="progress_bar"><span style={{ lineHeight: '25px', marginBottom: '0', color: qualityScore?.QualityGrade == 'Emerald(E)' ? '#FDB51F' : '#193057' }}>{qualityScore?.QualityGrade == 'Emerald(E)' ? 'E' : 'S'}</span></div>
                                                            <span class="opac">{qualityScore?.QualityScore == null ? 0 : qualityScore?.QualityScore}%</span>

                                                        </div>


                                                    </div>


                                                    <>


                                                        <div class="quality_detail">
                                                            <div class="quality_tag">
                                                                <h6>{t('title')}: {qualityScore?.TitleCount == null ? 0 : qualityScore?.TitleCount}/30</h6>
                                                                <p>{qualityScore?.TitleScore == null ? 0 : qualityScore?.TitleScore}%</p>
                                                            </div>
                                                            <div class="quality_tag">
                                                                <h6>{t('description')}: {qualityScore?.DescriptionCount == null ? 0 : qualityScore?.DescriptionCount}/800</h6>
                                                                <p>{qualityScore?.DescriptionScore == null ? 0 : qualityScore?.DescriptionScore}%</p>
                                                            </div>
                                                            <div class="quality_tag">
                                                                <h6>{t('images')}: {qualityScore?.ImageCount == null ? 0 : qualityScore?.ImageCount}/{qualityScore?.RecommendedImageCount == null ? 0 : qualityScore?.RecommendedImageCount}</h6>
                                                                <p>{qualityScore?.ImageCountScore == null ? 0 : qualityScore?.ImageCountScore}%</p>
                                                            </div>
                                                            <div class="quality_tag">
                                                                <h6>{t('duplicatePhotos')}: {qualityScore?.DuplicateImageCount == null ? 0 : qualityScore?.DuplicateImageCount}/0</h6>
                                                                <p>{qualityScore?.DuplicateImageScore == null ? 0 : qualityScore?.DuplicateImageScore}%</p>
                                                            </div>
                                                            <div class="quality_tag">
                                                                <h6>{t('photoSizes')}: {qualityScore?.ImageSizeCount == null ? 0 : qualityScore?.ImageSizeCount}/{qualityScore?.ImageCount == null ? 0 : qualityScore?.ImageCount}</h6>
                                                                <p>{qualityScore?.ImageSizeScore == null ? 0 : qualityScore?.ImageSizeScore}%</p>
                                                            </div>
                                                            <div class="quality_tag">
                                                                <h6>{t('ImageDiversity')}: {qualityScore?.ImageDiversity1Count == null ? 0 : qualityScore?.ImageDiversity1Count}/{qualityScore?.ImageCount == null ? 0 : qualityScore?.ImageCount}</h6>
                                                                <p>{qualityScore?.ImageDiversity1Score == null ? 0 : qualityScore?.ImageDiversity1Score}%</p>
                                                            </div>

                                                            <div class="quality_tag">
                                                                <h6>{t('additionalFields')}: {qualityScore?.NonMandatoryDataFilledCount == null ? 0 : qualityScore?.NonMandatoryDataFilledCount}/{qualityScore?.NonMandatoryRecommendedFieldCount == null ? 0 : qualityScore?.NonMandatoryRecommendedFieldCount}</h6>
                                                                <p>{qualityScore?.NonMandatoryFieldsScore == null ? 0 : qualityScore?.NonMandatoryFieldsScore}%</p>
                                                            </div>
                                                        </div>


                                                    </>



                                                </div>
                                                <h4>{t('management')}</h4>
                                                <div>
                                                    <FormControl className='form-group' sx={{}}>

                                                        <div class="form-line">
                                                            <input type="text" placeholder='Agent Reference*'
                                                                ref={field7Ref}
                                                                title='Agent Reference'
                                                                id="field7"
                                                                class="form-control" value={references}
                                                                onChange={(e) => { setReferences(e.target.value); validateReferences(e.target.value) }} />

                                                        </div>
                                                        {
                                                            referencesError && (
                                                                <span class="validation_notes">{referencesError}</span>

                                                            )
                                                        }
                                                    </FormControl>
                                                </div>
                                                {
                                                    userRole == 'AgentAdmin' && (
                                                        <div>
                                                            <FormControl className='form-group' sx={{}}>
                                                                <Select
                                                                    className='form-control border_temp'
                                                                    sx={{ borderColor: 'transparent' }}
                                                                    title={t('assignedUser')}
                                                                    displayEmpty
                                                                    value={assignedUser}
                                                                    onChange={(e) => { setAssignedUser(e.target.value); validateAssignedUser(e.target.value) }}
                                                                    input={<OutlinedInput />}

                                                                    MenuProps={{
                                                                        PaperProps: {
                                                                            style: {
                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                width: 250,
                                                                            },
                                                                        },
                                                                        disableScrollLock: true,
                                                                    }}
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                >
                                                                    <MenuItem className='drop_menus' value="">
                                                                        <em className='select_text'>{t('assignedUser')}</em>
                                                                    </MenuItem>
                                                                    {propertyMasterData?.Users?.map((item) => (
                                                                        <MenuItem
                                                                            className='drop_menus'
                                                                            key={item?.Id}
                                                                            value={item?.Id}
                                                                            style={getStyles(item?.Name, personName, theme)}
                                                                        >
                                                                            {item?.Name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {assignedUserError && (
                                                                    <span class="validation_notes">{assignedUserError}</span>
                                                                )}
                                                            </FormControl>
                                                        </div>
                                                    )
                                                }

                                                <div>
                                                    <FormControl className='form-group' sx={{}}>
                                                        <Select
                                                            className='form-control border_temp'
                                                            sx={{ borderColor: 'transparent' }}
                                                            title={t('owner')}
                                                            displayEmpty
                                                            value={owner}
                                                            onChange={(e) => { setOwner(e.target.value) }}
                                                            input={<OutlinedInput />}

                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                        width: 250,
                                                                    },
                                                                },
                                                                disableScrollLock: true,
                                                            }}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem className='drop_menus' value="">
                                                                <em className='select_text'>{t('owner')}</em>
                                                            </MenuItem>
                                                            {propertyMasterData?.Owners?.map((item) => (
                                                                <MenuItem
                                                                    className='drop_menus'
                                                                    key={item?.Id}
                                                                    value={item?.Id}
                                                                    style={getStyles(item?.Name, personName, theme)}
                                                                >
                                                                    {item?.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </FormControl>
                                                </div>
                                                <div class="availability_block">
                                                    <h6>{t('availability')}</h6>
                                                    {
                                                        propertyMasterData?.PropertyStatuses?.map((option) => {
                                                            return (<div class="checkbox">
                                                                <input type="checkbox" id={option?.Id}
                                                                    checked={selectedAvailabilityId === option?.Id}
                                                                    onChange={() => { handleAvailabilityChange(option?.Id) }} class="filled-in chk-col-pink" />
                                                                <label for={option?.Id}>{option?.Name}</label>
                                                            </div>)
                                                        })
                                                    }


                                                </div>
                                                <div>
                                                    <FormControl className='form-group' sx={{}}>
                                                        {
                                                            inputTypeDate == 'date' && (
                                                                <div class="availability_block">
                                                                    <h6>Available From</h6>
                                                                </div>

                                                            )
                                                        }


                                                        {
                                                            selectedAvailabilityId == 1 && (
                                                                <div class="form-line">
                                                                    <input type={inputTypeDate} min={today} value={availableFrom}
                                                                        onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                        onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                        onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                        onChange={(e) => setAvailableFrom(e.target.value)}
                                                                        onFocus={(e) => handleFocusDate(e)} title='Available From' placeholder='Available From' class="form-control" />

                                                                </div>
                                                            )
                                                        }
                                                    </FormControl>
                                                </div>
                                                <div class="availability_block">
                                                    <div class="checkbox">
                                                        <input type="checkbox" checked={isExclusive}
                                                            onChange={() => setIsExclusive(!isExclusive)} id="exclusivity_checkbox" class="filled-in chk-col-pink" />
                                                        <label for="exclusivity_checkbox">{t('exclusivity')}</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <FormControl className='form-group' sx={{}}>
                                                        {
                                                            inputTypeStartDate == 'date' && (
                                                                <div class="availability_block">
                                                                    <h6>Start Date</h6>
                                                                </div>

                                                            )
                                                        }

                                                        {
                                                            isExclusive && (
                                                                <>
                                                                    <div class="form-line">
                                                                        <input type={inputTypeStartDate} min={today}
                                                                            onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                            onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                            onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                            onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                            value={startDate} onChange={(e) => { setStartDate(e.target.value); validateStartDate(e.target.value) }}
                                                                            onFocus={(e) => handleFocusStartDate(e)} title='Start Date' placeholder='Start Date' class="form-control" />

                                                                    </div>
                                                                    {
                                                                        startDateError && (
                                                                            <span class="validation_notes">{startDateError}</span>

                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </FormControl>
                                                </div>

                                                <div>
                                                    <FormControl className='form-group' sx={{}}>
                                                        {
                                                            inputTypeExpiryDate == 'date' && (
                                                                <div class="availability_block">
                                                                    <h6>Expiry Date</h6>
                                                                </div>

                                                            )
                                                        }

                                                        {
                                                            isExclusive && (
                                                                <>
                                                                    <div class="form-line">
                                                                        <input type={inputTypeExpiryDate} min={today}
                                                                            onKeyDown={(e) => e.preventDefault()} // Prevent typing
                                                                            onKeyPress={(e) => e.preventDefault()} // Prevent typing
                                                                            onKeyUp={(e) => e.preventDefault()} // Prevent typing
                                                                            onPaste={(e) => e.preventDefault()} // Prevent pasting
                                                                            value={expiryDate} onChange={(e) => { setExpiryDate(e.target.value); validateExpiryDate(e.target.value) }}
                                                                            onFocus={(e) => handleFocusExpiryDate(e)} title='Expiry Date' placeholder='Expiry Date' class="form-control" />

                                                                    </div>
                                                                    {
                                                                        expiryDateError && (
                                                                            <span class="validation_notes">{expiryDateError}</span>

                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            {
                                value == 'two' ?
                                    <div>
                                        <div class="main_class">
                                            <p class="note" style={{ color: '#EC1A25' }}><span class="notes" style={{ color: '#EC1A25' }}>Note: </span>{t('photoRatio')}.</p>

                                            <div class="property_image_block">
                                                <div class="row clearfix">
                                                    <div class="col-md-3 col-sm-4 col-xs-6">
                                                        <div class="property_image">
                                                            <div class="file-drop-area images">
                                                                <figure><img src={require('../../images/upload_image.jpg')} /></figure>

                                                                <input class="file-input" multiple type="file" onChange={handleMediaFileChange} />

                                                            </div>
                                                        </div>
                                                    </div>


                                                    {
                                                        imageMediaListData?.map((item, index) => {

                                                            return (
                                                                <div class="col-md-3 col-sm-4 col-xs-6">
                                                                    <div class="property_image default">
                                                                        <img src={`${config.baseURL}${item?.Url}`} alt={item?.Id} />
                                                                        {
                                                                            index == 0 ? (
                                                                                <div class="default_block">
                                                                                    <a class="default_image">
                                                                                        {t('Default Image')}
                                                                                    </a>
                                                                                    <a style={{ width: 20, height: 20, }} class="delete_image" onClick={() => onClickMediaImageDelete(item?.Id)}>
                                                                                        <img style={{ width: 10, height: 10 }} src={delete_icon}></img>
                                                                                    </a>
                                                                                </div>
                                                                            ) :
                                                                                (
                                                                                    <div class="default_block">
                                                                                        <a style={{ width: 20, height: 20, }} class="delete_image" onClick={() => onClickMediaImageDelete(item?.Id)}>
                                                                                            <img style={{ width: 10, height: 10 }} src={delete_icon}></img>
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }



                                                </div>
                                            </div>
                                            <div class="form_block">
                                                <div class="row clearfix">
                                                    <div className="col-sm-12">
                                                        <div class="form-group">
                                                            <div class="form-line">
                                                                <input type="text" class="form-control border" title={t('360URLLink')} placeholder={t('360URLLink')} value={urlLink_360} onChange={(e) => { setUrlLink_360(e.target.value); }} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="row clearfix">
                                                    <div className="col-sm-12">
                                                        <div class="form-group">
                                                            <div class="form-line">
                                                            <p class="note" style={{ color: '#EC1A25' }}><span class="notes" style={{ color: '#EC1A25' }}>Note: </span>Please enter a valid YouTube link for the property video tour. For example: https://www.youtube.com/watch?v=abc123xyz.</p>
                                                                <input type="text" class="form-control border" title={t('videoTourURL')} placeholder={t('videoTourURL')} value={videoTourUrl} onChange={(e) => { setVideoTourUrl(e.target.value); }} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="button_block">




                                                <a href="#" class="links" onClick={() => mediaApi()}>{t('Save')}</a>
                                            </div>
                                        </div>

                                        <div class="panel-group full-body" id="accordion_2" role="tablist" aria-multiselectable="true">
                                            <div class="panel">
                                                <div class="panel-heading" role="tab" id="headingOne_2">
                                                    <h4 class="panel-title">
                                                        <a role="button" onClick={() => openCloseFloorAccordingList()} data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne_2">
                                                            <span>{t('floorPlan')}</span>
                                                            <i className={floorAccordingList ? 'openIcon' : 'closeIcon'}></i>
                                                        </a>
                                                    </h4>
                                                </div>
                                                {floorAccordingList ?
                                                    <>
                                                        <div id="collapseOne_2" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_2">
                                                            <div class="panel-body">
                                                                <div class="card">
                                                                    <div class="main_class">
                                                                        <p>{t('myFloorPlan')}</p>
                                                                        <div class="form-group">
                                                                            <div class="form-line">
                                                                                <div class="custom-file">
                                                                                    <input class="custom-file-input" type="file" onChange={handleFloorPlanUpload} />
                                                                                    <label class="custom-file-label" for="customFile">{"Select Your Floor Plan"}</label>

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
                                            </div>

                                        </div>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="table-responsive">
                                                    {
                                                        floorPlanDocListData?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable custom">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr. No.</th>
                                                                        <th>{t('name')}</th>

                                                                        <th>{t('fileName')}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        floorPlanDocListData?.Data?.map((item) => {
                                                                            return (

                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{item?.RowNum}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Title}</p>
                                                                                    </td>

                                                                                    <td>
                                                                                        <a onClick={() => handleDownload(item)}>
                                                                                            <p class='blue_text'>{item?.FileName}</p>
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail">
                                                                                            <a href="#" onClick={() => handleFloorClickOpen(item?.Id)} class="link red"><img src={deleteIcon} /></a>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table> :
                                                            <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={floorPlanDocListData?.TotalPages} page={floorPlanPage} onChange={pageHandleChange} />
                                                </div>
                                            </div>

                                        </div>
                                        <div class="button_block">

                                            <a href="#" class="links" onClick={() => setValue('one')} >{t('back')}</a>


                                            <a href="#" class="links" onClick={() => nextMediaPage()}>{t('next')}</a>
                                        </div>
                                    </div>

                                    : null
                            }



                            {
                                value == 'three' ?
                                    <>
                                        <div class="main_class">
                                            <div class="form_block">
                                                <div class="form-group notes">
                                                    <div class="form-line">
                                                        <textarea rows="4" class="form-control no-resize" value={notes} placeholder={t('WriteNewNote')} onChange={(e) => { setNotes(e.target.value); validateNotes(e.target.value) }}></textarea>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="button_block">
                                                <a href="#" class="links" onClick={() => NotesApi()}>{t('Save')}</a>
                                            </div>
                                            {
                                                notesError && (
                                                    <span class="validation_notes">{notesError}</span>

                                                )
                                            }
                                        </div>
                                        {
                                            notesPropertyData?.Data?.map((item) => {
                                                return (
                                                    <div class="main_class">
                                                        <div class="note_block">
                                                            <div class="note_person">
                                                                <div class="note_person_image">
                                                                    <img src={require("../../images/note_person_image.png")} />
                                                                    <div class="person_detail">
                                                                        <h5>{item?.UserName}</h5>
                                                                        <p>{t('lastUpdate')}: {item?.DateDifference}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="note_time">

                                                                </div>
                                                            </div>
                                                            <p>{item?.Notes}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        <div class="button_block">

                                            <a href="#" class="links" onClick={() => setValue('two')}>{t('back')}</a>



                                            <a href="#" class="links" onClick={() => noteNextTab()}>NEXT</a>
                                        </div>
                                    </> : null
                            }

                            {
                                value == 'four' ?
                                    <>
                                        <div class="main_class">
                                            <div class="file-drop-area">
                                                <img src={folder} />
                                                <span class="file-message">{t('dragandDrop')}.</span>
                                                <span class="choose-file-button">{t('uploadDocument')}</span>
                                                <input class="file-input" type="file" onChange={handleFileChange} />

                                            </div>
                                        </div>
                                        <div class="main_class">
                                            <div class="table_block">
                                                <div class="table-responsive">
                                                    {
                                                        propertyDocumentsListData?.Data?.length != 0 ?
                                                            <table class="table table-bordered table-striped table-hover dataTable custom">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Sr. No.</th>
                                                                        <th>{t('name')}</th>
                                                                        <th>{t('fileName')}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        propertyDocumentsListData?.Data?.map((item) => {
                                                                            return (

                                                                                <tr>
                                                                                    <td>
                                                                                        <p>{item?.RowNum}</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p>{item?.Title}</p>
                                                                                    </td>

                                                                                    <td>
                                                                                        <a onClick={() => handleDownload(item)}>
                                                                                            <p class='blue_text'>{item?.FileName}</p>
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="table_detail">
                                                                                            <a href="#" onClick={() => handleClickOpen(item?.Id)} class="link"><img src={deleteIcon} /></a>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table> :
                                                            <h1 class="text-center">No Data Found</h1>
                                                    }

                                                </div>
                                                <div class="text-right">
                                                    <Pagination className="pagination" count={propertyDocumentsListData?.TotalPages} page={pageDocumentList} onChange={pageHandleChangeDocumentList} />
                                                </div>
                                            </div>
                                            <div class="button_block">

                                                <a href="#" class="links" onClick={() => setValue('three')}>{t('back')}</a>


                                                <a href="#" class="links" onClick={(e) => DocumentsApi(e)}>{t('done')}</a>
                                            </div>
                                        </div>
                                    </> : null
                            }
                        </div>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">{t('selectAmenities')}</h2>
                            <button onClick={handleClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <p>{t('amenitiesList')}</p>
                            <div class="amenities_list">
                                <div>
                                    {propertyMasterData?.Amenities?.map((chip) => (
                                        <Chip
                                            key={chip?.Id}
                                            label={chip?.Name}
                                            color={selectedChips?.includes(chip?.Id) ? 'primary' : 'default'}
                                            onClick={() => handleChipClick(chip?.Id)}
                                            style={{ margin: '5px', fontSize: '14px', padding: '6px 15px', borderRadius: '3px' }}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links" onClick={() => {
                                    setSelectedChips(selectAminitiesSelected?.map((item) => item?.Id))
                                    setOpen(false);
                                    scroller.scrollTo(fieldDefaultRef.current.id, {
                                        smooth: true,
                                        offset: -100, // Adjust as needed
                                    });
                                }}>{t('cancel')}</a>
                                <a href="#" class="links" onClick={() => {
                                    onClickAmenities();
                                    scroller.scrollTo(fieldDefaultRef.current.id, {
                                        smooth: true,
                                        offset: -100, // Adjust as needed
                                    });
                                }}>{t('saveAmenities')}</a>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={upgradePlanOpen}
                    onClose={handleUpgradePlanClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block upgrade">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Boost on Yspace.ai</h2>
                            <button onClick={handleUpgradePlanClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <div class="credit_numbers">
                                <p>Select the type of boost you want to give to your listing.</p>
                                <div class="avail_credits">
                                    <p class="blue_text"><img src={require("../../images/logo_icon.png")} />Available Credits: <span>{checkPublishCreditsData?.AvailableCredits}</span></p>
                                </div>
                            </div>
                            <div class="upgrade_plan_block">
                                {
                                    propertyCurrentSubscriptionPlanId == 1 && (
                                        <div class={mainSubscriptionTypeSelected == 1 ? "upgrade_plan_detail active" : "upgrade_plan_detail"} onClick={() => setMainSubscriptionTypeSelected(1)}>
                                            <h5>Featured</h5>
                                            <div class="duration_block">
                                                <p>Duration</p>
                                                <div class="form_block">
                                                    <FormControl>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue="15 days"
                                                            name="radio-buttons-group"
                                                            class="radio_button_block"

                                                        >
                                                            <FormControlLabel value="15 days" control={<Radio />} label="15 days" onClick={() => setSubscriptionIdSelectedFeatured(2)} />

                                                            <FormControlLabel value="1 Month" control={<Radio />} label="1 month" onClick={() => setSubscriptionIdSelectedFeatured(3)} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                            </div>


                                            <div class="table_heading">
                                                <div style={{ display: 'inline-block' }} >
                                                    <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} class='autorenew_check' control={<Checkbox checked={featuredAutoRenewCheckBox} onChange={handleFeaturedAutoRenewCheckBox} />} label="Auto-renew" />
                                                </div>

                                            </div>
                                            <div class="upgrade_credit_block">
                                                <h6>{subscriptionIdSelectedFeatured == 2 ? checkPublishCreditsData?.DeductionCreditsForFeatured_15_Days : checkPublishCreditsData?.DeductionCreditsForFeatured_1_Month} Credits</h6>

                                            </div>


                                        </div>
                                    )
                                }
                                <div class={mainSubscriptionTypeSelected == 2 ? "upgrade_plan_detail active" : "upgrade_plan_detail"} onClick={() => setMainSubscriptionTypeSelected(2)}>
                                    <h5>Premium</h5>
                                    <div class="duration_block">
                                        <p>Duration</p>
                                        <div class="form_block">
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="15 days"
                                                    name="radio-buttons-group"
                                                    class="radio_button_block"
                                                >
                                                    <FormControlLabel value="15 days" control={<Radio />} label="15 days" onClick={() => setSubscriptionIdSelectedPremium(4)} />

                                                    <FormControlLabel value="1 Month" control={<Radio />} label="1 month" onClick={() => setSubscriptionIdSelectedPremium(5)} />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div class="table_heading">
                                        <div style={{ display: 'inline-block' }} >
                                            <FormControlLabel sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} class='autorenew_check' control={<Checkbox checked={premiumAutoRenewCheckBox} onChange={handlePremiumAutoRenewCheckBox} />} label="Auto-renew" />
                                        </div>

                                    </div>
                                    <div class="upgrade_credit_block">
                                        <h6>{subscriptionIdSelectedPremium == 4 ? checkPublishCreditsData?.DeductionCreditsForPremium_15_Days : checkPublishCreditsData?.DeductionCreditsForPremium_1_Month} Credits</h6>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links" style={{
                                    color: loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: loading ? 'none' : "auto", // Disable pointer events when disabled

                                }} onClick={() => handleUpgradePlanClose()}>Cancel</a>
                                <a href="#" class="links" onClick={() => onClickPublishUpgrade()} style={{
                                    color: mainSubscriptionTypeSelected == "" || loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: mainSubscriptionTypeSelected == "" || loading ? 'none' : "auto", // Disable pointer events when disabled

                                }}>Apply</a>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={publishOpen}
                    onClose={handlePublishClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <div class="modal_block upgrade">
                        <div class="modal_header_block">
                            <h2 id="child-modal-title">Publish Properties</h2>
                            <button onClick={handlePublishClose}><img src={closeModal} /></button>
                        </div>
                        <div class="modal_body_block">
                            <div class="publish_properties_block">
                                <img style={{ height: 85, marginLeft: -10 }} src={require("../../images/logo.png")} />
                                {
                                    checkPublishCreditsData?.Status == "true" ?
                                        <>
                                            <p><span class="red_text">{checkPublishCreditsData?.DeductionCreditForStandard} credits</span> will be deducted from your balance.</p>
                                            <h6>Available Credits : {checkPublishCreditsData?.AvailableCredits}</h6>
                                        </>
                                        :
                                        <p><span class="red_text">This property cannot be published as it may have incomplete information to publish to portals.</span></p>
                                }

                            </div>
                        </div>
                        <div class="modal_footer_block">
                            <div class="button_block">
                                <a href="#" class="links" style={{
                                    color: loading ? '#999' : "inherit",// Change color when disabled
                                    pointerEvents: loading ? 'none' : "auto", // Disable pointer events when disabled

                                }} onClick={() => handlePublishClose()}>Cancel</a>
                                {
                                    checkPublishCreditsData?.Status == "true" && (
                                        <a href="#" class="links" style={{
                                            color: loading ? '#999' : "inherit",// Change color when disabled
                                            pointerEvents: loading ? 'none' : "auto", // Disable pointer events when disabled

                                        }} onClick={() => onClickPublish()}>Publish</a>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </Modal>
            </section >
            <ToastContainer />

        </div >
    );

}





